import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./UsersTable.css";
import {
  getUser,
  unLockUserAcc,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { KTCard, KTSVG } from "../../../theme/helpers";
import ActionPopupDev from "./ActionPopupDev";
import AdvancedFilterPopup from "../../modules/widgets/components/Filterpopup";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import { useAuth } from "../../modules/auth";
import CustomLoadingCellRender from "./CustomLoadingCellRender";
import { HandleApiCall } from "../../modules/auth/functions/HandleApiCall";
import {
  formatDatesinArray,
} from "../../../theme/assets/ts/_utils/FormatData";
import { userTableConfig } from "./UserTableConfig";
import { useTranslation } from "react-i18next";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import SearchInputDev from "../../utils/SearchInputDev";
import DevExport from "../../modules/custom components/Export/DevExport";
import DataGridWrapper from "../../utils/DataGrid/DataGridWrapper";

const UsersTable = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const gridRef: any = useRef(null);
  const fileName = "users_list";
  const [trackLoadingState, setTrackLoadingState] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [sortData, setSortData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isadvanceShort, setIsadvanceShort] = useState(false);
  const [gridApi, setGridApi] = useState<any>(null);
  const { setEditable,currentUser } = useAuth();
  const currentPath = window.location.pathname;
  const localStorageKeyFilter = `filters_${currentPath}`;
  const localStorageKeySort=`sorting${currentPath}`
  const location=useLocation();
  const roles=currentUser?.roles;

  useEffect(() => {
      // Clear local storage when navigating away from the component
      return () => {
        localStorage.removeItem(localStorageKeyFilter);
        localStorage.removeItem(localStorageKeySort);
      };
    }, [localStorageKeyFilter,localStorageKeySort, location]);

  const columnOptions = [
    { value: "USERID", label: "User ID", type: "text" },
    { value: "USERNAME", label: "Name", type: "text" },
    { value: "USER_EMAIL", label: "Email", type: "text" },
    { value: "EQUV_USER", label: "Equiv. User ID", type: "text" },
    { value: "START_DATE", label: "Start Date", type: "date" },
    { value: "END_DATE", label: "End Date", type: "date" },
  ];

  const [exportAllData,setExportAllData]=useState([]);
  const fetchUserAPI = async()=>{
    setTrackLoadingState(true);
    try {
      const response=await getUser(
            "",
            { advancedFilters: filterData, sortedList: sortData },
            "",
            ""
          );
        if(response.data){
          setRowData(formatDatesinArray(response?.data));
          if(filterData.length===0){
            setExportAllData(response?.data);
          }
        }

      
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }

  };

  const closeChangePasswordModal = (event) => {
    setIsadvanceShort(false);
  };

  useEffect(() => {
    fetchUserAPI();
  }, [filterData,sortData]);

  const CustomCheckbox = (props) => {
    const [isLocked,setIsLocked]=useState(props?.data?.accountLocked);
    const handleLockClick = async () => {
      const responseofUnLockUserAcc = await HandleApiCall(
        unLockUserAcc,
        props?.data?.userId
      );
      if (responseofUnLockUserAcc?.errorCode === 0) {
        setIsLocked(false);
      }
    };

    return (
      <div className="d-flex justify-content-center align-items-center">
        <label className="mt-2">{props?.data?.userId}</label>
        {roles==='A'&&isLocked && (
          <div
            onClick={() => handleLockClick()}
            className="mb-1"
          >
            <KTSVG
              path="/media/icons/duotune/general/lock.svg"
              className="svg-icon-4 position-relative ms-3 mt-5"
            />
          </div>
        )}
      </div>
    );
  };


  const columnDefs = [
    {
      headerName: "User ID",
      caption: "User ID",
      columnName: "User ID",
      field: "userId",
      width: 100,
      rowDrag: true,
      columnType:"Char",
      cellRenderer: CustomCheckbox,             
    },
    {
      headerName: "Name",
      caption: "Name",
      columnName: "Name",
      field: "userName",
      columnType:"Char",
      minWidth:50,
    },
    {
      headerName: "Email",
      caption: "Email",
      columnName: "Email",
      field: "userEmail",
      columnType:"Char",
      minWidth: 100,
    },
    {
      headerName: "Equiv. User ID",
      caption: "Equiv. User ID",
      columnName: "Equiv. User ID",
      field: "equivalentUser",
      columnType:"Char",
      minWidth: 50,
    },
    {
      headerName: "Start Date",
      caption: "Start Date",
      columnName: "Start Date",
      field: "startDate",
      columnType:"Date",
      minWidth: 100,
    },
    {
      headerName: "End Date",
      caption: "End Date",
      ColumnName: "End Date",
      field: "endDate",
      columnType:"Date",
      minWidth: 100,
    },
    {
      headerName: "Actions",
      caption: "Actions",
      columnName: "Actions",
      field: "actions",
      cellRenderer: ActionPopupDev,
      resizable: false,
      halign: "center",
      sortable: false,
      filter: false,
      width: 100,
    },
  ];


  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      resizable: true,
      minWidth: 150,
    }),
    []
  );

  const onGridReady = (params) => {
    // if (window.innerWidth < 800) {
    //   sizeToFit();
    // }
    setGridApi(params.component);
  };

  const sizeToFit = () => {
    if (gridRef.current) {
      gridRef.current.sizeColumnsToFit({
        defaultMinWidth: 150,
      });
    }
  };

  const onShortButtonClick = () => {
    setIsadvanceShort(!isadvanceShort);
  };
  const onFilterButtonClick = () => {
    setShowFilter(!showFilter);
  };

  const handlecreateuser = () => {
    setEditable(false);
    navigate("/createuser");
  };

  const handleApply = (data) => {
    setFilterData(data);
  };

  const handleApplysort = (data) => {
    const sortPayload = data.map(({...newObject }) => ({
      column: newObject.column,
      ascDesc: newObject.ascDesc
    }));
    setSortData(sortPayload);
  };

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 150, 200];
  }, []);

  return (
    <>
      <div>
        <div
          className=" d-flex"
          style={{
            marginBottom: 15,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      </div>
      <KTCard className=" px-10">
        <div className="mb-5 w-100">
          <div
            className="mt-5 row row-cols-1 row-cols-md-4 gy-3 w-100"
            style={{ alignItems: "center" }}
          >
             <SearchInputDev gridRef={gridRef} type={"userList"} className={"col-md-6"} screen={"UserTable"}/>
            {/* <div className="col-md-2">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                onClick={handlecreateuser}
                title={t('Create User')}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                <FaPlus style={{ marginRight: "5px" }} size={15} />
                {t('Create User')}
              </button>
            </div> */}
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                onClick={onShortButtonClick}
                title={t('Sort')}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
              >
                {t('Sort')}
              </button>
            </div>
            <div className="col-md-2">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                onClick={onFilterButtonClick}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0a7eac",
                }}
                title={t("Filter")}
              >
                {t("Filter")}
              </button>
            </div>
            {showFilter && (
              <AdvancedFilterPopup
                onClose={() => {
                  setShowFilter(false);
                }}
                columnOptions={columnOptions}
                onApply={handleApply}
              />
            )}
            {isadvanceShort && (
              <UserEditModal
                content="advancesort"
                modalText={t("Sort")}
                onCloseEditModal={closeChangePasswordModal}
                user={userTableConfig}
                onApply={handleApplysort}
                style={{
                  position: "relative",
                  zIndex: 1000,
                }}
              />
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-end mb-5">
          <div></div>

          <div className="icon"></div>

          {/* <div className="icon me-5 d-flex gap-5">
            <DevExport
            gridRef={gridRef}
            apiData={exportAllData}
            file={fileName}
            columnDefs={columnDefs}
            // backendDef={columnDefs}
             />
          </div> */}
        </div>

        {trackLoadingState?(<CustomLoadingCellRender/>):(
           <DataGridWrapper
           gridRef={gridRef}
           rowData={rowData}
           columnDefs={columnDefs}
           defaultColDef={defaultColDef}
           rowDragManaged={true}
           animateRows={true}
           onGridReady={onGridReady}
           pagination={true}
           paginationPageSize={50}
           paginationPageSizeSelector={paginationPageSizeSelector}
           height={498}
           exportEnabled={true}
           fontSizeForPdfExport={10}
           fileName={fileName}
           />
       )}
      </KTCard>
     
    </>
  );
};

export default UsersTable;
