import { useNavigate } from "react-router-dom";
import "./CompanyModal.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const CompanyModal = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const companyName = useSelector((state: any) => state?.userConfig?.companyName);
  return (
    <div
      className="login-wrapper menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-2 fs-6 me-1"
      data-kt-menu="true"
      style={{ width: "auto", minWidth: "150px", maxWidth: "fit-content" }}
    >
      <div className="menu-item w-100">
        <div className="menu-content d-flex align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <div className="text-center px-3"
              style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100%"}}>
              {companyName}
            </div>
            <div className="separator my-3"></div>
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
              <button
                className="py-2 px-4 btn text-white rounded-pill"
                style={{ backgroundColor: "#0a7eac" }}
                onClick={() => navigate("/selectcompany", { state: "change" })}
              >
                {t("Change Company")}
              </button>
            </div>
          </div>
        </div>
        <div className="my-1"></div>
      </div>
    </div>
  );
};

export default CompanyModal;
