import { useState, useRef } from "react";
import Tippy from "@tippyjs/react";
import "./ActionPopup.css";
import { KTSVG } from "../../../theme/helpers";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../modules/auth";
import { updateLookupData, updateStockCode } from "../../../reducers/lookupReducer";
import { useDispatch, useSelector } from "react-redux";
import { copyInvoice, deleteInvoice, generatePickupSlip } from "../../modules/apps/user-management/users-list/core/_requests";
import { updateIsCreateRequest, updateIsValidRouteForRequestStock } from "../../../reducers/indexReducer";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import { useTranslation } from "react-i18next";

const ActionPopupDev = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const status = props?.data?.status;
  const currentDate = new Date(Date.now());
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}`;
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const formattedTime = `${hours}:${minutes}:${seconds}`;
  const {
    setEditable,
    setDeleteid,
    setPageStatus,
    setGroup,
    setSelectedRowIndex,
  } = useAuth();
  const company = useSelector((state: any) => state?.userConfig?.company);
  const tippyRef: any = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const includesStock = currentUrl?.includes("/stock");
  const invoiceFlow = currentUrl?.includes("/invoice");
  const invoiceBatchFlow = currentUrl?.includes("/createInvoiceBatch");
  const closeUnprocessedFlow = currentUrl?.includes("/unprocessedInvoice");
  const approveInvoiceFlow = currentUrl?.includes("/approveInvoice");
  
  const bankFlow = currentUrl?.includes("/bankDetailList");
  const approveBankFlow = currentUrl?.includes("/bankApprovalList");
  const requestNoValue = props?.data?.requestNo;

  const contractFlow = currentUrl?.includes("/contractList");
  const approveContactFlow = currentUrl?.includes("/approveContract");


  // Store the value in local storage
  localStorage.setItem("deleteRequestNo", requestNoValue);
  // const dropDownContent = includesStock ? (
  //   <div className="menu-container shadow-sm">
  //     <div
  //       onClick={() => onClickstock("view")}
  //       className="menu-items text-hover-primary"
  //     >
  //       View
  //     </div>
  //     {status == "Not Submitted yet" && (
  //       <div
  //         onClick={() => onClickstock("edit")}
  //         className="menu-items text-hover-primary"
  //       >
  //         Edit
  //       </div>
  //     )}
  //     {(status == "Not Submitted yet" ||
  //       status == "In Process" ) && (
  //       <div
  //         onClick={() => onClickstock("delete")}
  //         className="menu-items text-hover-primary"
  //       >
  //         Delete
  //       </div>
  //     )}
  //     {status == "Fully Authorized" && (
  //       <div
  //         onClick={() => onClickstock("picking-slip")}
  //         className="menu-items text-hover-primary"
  //       >
  //         View Picking Slip
  //       </div>
  //     )}
  //   </div>
  // ) : (
  //   <div className="menu-container shadow-sm">
  //     <div
  //       onClick={() => onClickHandler("view")}
  //       className="menu-items text-hover-primary"
  //     >
  //       View
  //     </div>
  //     <div
  //       onClick={() => onClickHandler("edit-access")}
  //       className="menu-items text-hover-primary"
  //     >
  //       Edit Access
  //     </div>
  //     <div
  //       onClick={() => onClickHandler("edit")}
  //       className="menu-items text-hover-primary"
  //     >
  //       Edit Details
  //     </div>
  //   </div>
  // );

  let dropDownContent;

  switch (true) {
    case includesStock:
      dropDownContent = (
        <div className="menu-container shadow-sm">
          <div
            onClick={() => onClickstock("view")}
            className="menu-items text-hover-primary"
          >
            View
          </div>
          {status === "Not Submitted yet" && (
            <div
              onClick={() => onClickstock("edit")}
              className="menu-items text-hover-primary"
            >
              Edit
            </div>
          )}
          {(status === "Not Submitted yet" || status === "In Process") && (
            <div
              onClick={() => onClickstock("delete")}
              className="menu-items text-hover-primary"
            >
              Delete
            </div>
          )}
          {status === "Fully Authorized" && (
            <div
              onClick={() => onClickstock("picking-slip")}
              className="menu-items text-hover-primary"
            >
              View Picking Slip
            </div>
          )}
        </div>
      );
      break;

    case (invoiceFlow):
      dropDownContent = (
        <div className="menu-container shadow-sm">
          <div
            onClick={() => onClickInvoice("view")}
            className="menu-items text-hover-primary"
          >
            View
          </div>
          <div
            onClick={() => onClickInvoice("edit")}
            className="menu-items text-hover-primary"
          >
            Edit
          </div>
          <div
            onClick={() => onClickInvoice("delete")}
            className="menu-items text-hover-primary"
          >
            Delete
          </div>
          <div
            onClick={() => onClickInvoice("copy")}
            className="menu-items text-hover-primary"
          >
            Copy
          </div>
        </div>
      );
      break;

    case (approveInvoiceFlow || invoiceBatchFlow || closeUnprocessedFlow):
      dropDownContent = (
        <div className="menu-container shadow-sm">
          <div
            onClick={() => onClickInvoice("view")}
            className="menu-items text-hover-primary"
          >
            View
          </div>
          <div
            onClick={() => onClickInvoice("edit")}
            className="menu-items text-hover-primary"
          >
            Edit
          </div>
          <div
            onClick={() => onClickInvoice("delete")}
            className="menu-items text-hover-primary"
          >
            Delete
          </div>
        </div>
      );
      break;

      case (bankFlow):
        dropDownContent = (
          <div className="menu-container shadow-sm">
            <div
              onClick={() => onClickBank("view")}
              className="menu-items text-hover-primary"
            >
              View
            </div>
            <div
              onClick={() => onClickBank("edit")}
              className="menu-items text-hover-primary"
            >
              Edit
            </div>
            <div
              onClick={() => onClickBank("delete")}
              className="menu-items text-hover-primary"
            >
              Delete
            </div>
          </div>
        );
        break;

        case (approveBankFlow):
          dropDownContent = (
            <div className="menu-container shadow-sm">
              <div
                onClick={() => onClickBank("view")}
                className="menu-items text-hover-primary"
              >
                View
              </div>
              <div
                onClick={() => onClickBank("delete")}
                className="menu-items text-hover-primary"
              >
                Delete
              </div>
            </div>
          );
          break;

          case (contractFlow || approveContactFlow):
            dropDownContent = (
              <div className="menu-container shadow-sm">
                 <div
                  onClick={() => onClickContract("view")}
                  className="menu-items text-hover-primary"
                >
                  View
                </div>
                {
                  ((props.data.status !== 'SX') && (props.data.status !== 'S5')) && (
                    <div onClick={() => onClickContract("edit")} className="menu-items text-hover-primary">Edit</div>
                  )
                }
                <div
                  onClick={() => onClickContract("delete")}
                  className="menu-items text-hover-primary"
                >
                  Delete
                </div>
              </div>
            );
            break;

    default:
      dropDownContent = (
        <div className="menu-container shadow-sm">
          <div
            onClick={() => onClickHandler("view")}
            className="menu-items text-hover-primary"
          >
            View
          </div>
          {/* <div
            onClick={() => onClickHandler("edit-access")}
            className="menu-items text-hover-primary"
          >
            Edit Access
          </div> */}
          <div
            onClick={() => onClickHandler("edit")}
            className="menu-items text-hover-primary"
          >
            Edit Details
          </div>
        </div>
      );
      break;
  }

  const copyInvoiceAndRedirect = async (invoiceId, stateData) => {
    try {
      const response = await copyInvoice(invoiceId, company);
      if (response && response.errorCode === 0 && response.data) {
        const stateInfo = {
          ...stateData,
          invoiceId: response.data.seqNo
        };
        navigate("/createInvoice", { state: stateInfo });
      } else {
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");
        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    }
  }
  

  const onClickInvoice = (option) => {
    hide();
    switch (option) {
      case "view":
        navigate('/createInvoice', { 
          state: { ...props.data, invoiceId: props.data.seqNo, previousPage: closeUnprocessedFlow ? 'close' : approveInvoiceFlow ? 'approve' : invoiceBatchFlow ? 'issue' : 'create', isViewOnly: true }
        });
        break;
      case "edit":
        navigate("/createInvoice", { 
          state: {...props.data, invoiceId: props.data.seqNo, previousPage: closeUnprocessedFlow ? 'close' : approveInvoiceFlow ? 'approve' : invoiceBatchFlow ? 'issue' : 'create'}
        });
        break;
      case "delete":
        localStorage.setItem("deleteInvoiceNo", props.data.seqNo);
        setDeleteid(true);
        break;
      case "copy":
        const stateData = {...props.data, invoiceId: props.data.seqNo, previousPage: closeUnprocessedFlow ? 'close' : approveInvoiceFlow ? 'approve' : invoiceBatchFlow ? 'issue' : 'create'}
        copyInvoiceAndRedirect(props.data.seqNo, stateData );
        break;
        default:
        break;
    }
  }


  const onClickBank = (option) => {
    hide();
    switch (option) {
      case "view":
        navigate('/editBankDetails', { 
          state: { ...props.data, bankDetailId: props.data.seq_no, previousPage: approveBankFlow ? 'approve' : 'create', isViewOnly: true }
        });
        break;
      case "edit":
        navigate("/editBankDetails", { 
          state: {...props.data, bankDetailId: props.data.seq_no, previousPage: approveBankFlow ? 'approve' : 'create'}
        });
        break;
      case "delete":
        localStorage.setItem("deleteBankDetailSeqNo", props.data.seq_no);
        setDeleteid(true);
        break;
        default:
        break;
    }
  }

  const onClickContract = (option) => {
    hide();
    switch (option) {
      case "view":
        navigate('/editContractDetail', { 
          state: { ...props.data, contractId: props.data.seqNo, previousPage: contractFlow ? 'create' : approveContactFlow ? 'approve' :'', isViewOnly: true }
        });
        break;
      case "edit":
        navigate("/editContractDetail", { 
          state: {...props.data, contractId: props.data.seqNo, previousPage: contractFlow ? 'create' : approveContactFlow ? 'approve' :'', isViewOnly: false }
        });
        break;
      case "delete":
        localStorage.setItem("deleteContractId", props.data.seqNo);
        setDeleteid(true);
        break;
        default:
        break;
    }
  }

  const onClickstock = (option) => {
    hide();
    switch (option) {
      case "view":
        setSelectedRowIndex(0);
        setGroup(null);
        setEditable(false);
        setPageStatus("View");
        dispatch(updateStockCode(""));
        dispatch(updateIsCreateRequest(false)); 
        dispatch(updateIsValidRouteForRequestStock(true));
        navigate("/requeststock", {
          state: { ...props.data, isViewOnly: true },
        });
        break;
      case "edit":
        setSelectedRowIndex(0);
        setGroup(null);
        setEditable(true);
        dispatch(updateStockCode(""));                   //It will avoid an addition api call for the stock code that was last added.
        dispatch(updateIsCreateRequest(false));
        dispatch(updateIsValidRouteForRequestStock(true));
        navigate("/requeststock", { state: props.data });
        setPageStatus("Edit");
        break;
      case "delete":
        setDeleteid(true);

        break;
      case "picking-slip":
        generatePickupSlip(
          {
            companyCode: company,
            generateDate: formattedDate,
            generateTime: formattedTime,
            reqNo: props.data.requestNo,
            status: props.data.status,
          },
          props?.data?.lineItems || []
        );

        break;

      default:
        break;
    }
  };

  const onClickHandler = (option) => {
    hide();
    let state = { data: props.data, isEditAccess: false };

    switch (option) {
      case "view":
        setEditable(false);
        navigate("/details", { state });
        break;
      case "edit-access":
        state.isEditAccess = true;
        setEditable(true);
        navigate("/details", { state });
        break;
      case "edit":
        setEditable(true);
        navigate("/createuser", { state: props?.data?.userId });
        break;
      case "copy":
        break;
      default:
        break;
    }
  };

  const onClick = (event) => {
    event.preventDefault();
    if (visible) {
      hide();
    } else {
      show();
    }
  };

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <a
        href="#!"
        className="btn btn-light btn-active-light-primary btn-sm action"
        onClick={onClick}
      >
        Actions
        <KTSVG
          path="/media/icons/duotune/arrows/arr072.svg"
          className="svg-icon-5 m-0"
        />
      </a>
    </Tippy>
  );
};

export default ActionPopupDev;
