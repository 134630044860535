import React, { useEffect, useRef, useState } from 'react'
import DevDynamicGridTable from '../PO-Stock-Enquiry/DevDynamicGridTable'
import CustomLoaders from '../user/CustomLoaders'
import { KTCard } from '../../../theme/helpers'
import CustomLoadingCellRender from '../user/CustomLoadingCellRender'
import { useAuth } from '../../modules/auth'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Row } from 'react-bootstrap'
import { DeleteModal } from '../../modules/widgets/components/DeleteModal'
import { approveContract, deleteContractDetails, getContractList, rejectContract, submitContract } from '../../modules/apps/user-management/users-list/core/_requests'
import { toastMessage } from '../../modules/auth/functions/toastMessage'
import { Popup } from 'devextreme-react/popup';
import { Field, Form, Formik } from 'formik'

export default function ContractListing({ type }) {

  const [loading, setLoading] = useState(false)
  const [trackLoadingState, setTrackLoadingState] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [columnDefs, setColumnDefs] = useState<any>([]);
  const [selectedDataRow, setSelectedDataRow] = useState([]);
  const [commentVisibilityFlag, setCommentVisibilityFlag] = useState(false)
  const [commentValue, setCommentValue] = useState('');
  const [actionType, setActionType] = useState('');

  const [tableConfiguration, setTableConfiguration] = useState<any>();
  const [tabStorage, setTableStorage] = useState();
  const [tabSqlName, setTabSqlName] = useState();

  const [option, setOption] = useState([]);
  const [isToggled, setIsToggled] = useState(false)
  const [enableGridCustomization, setEnableGridCustomization] = useState<Boolean>(false)
  const { setDeleteid, deleteid, currentUser } = useAuth();
  const company = useSelector((state: any) => state?.userConfig?.company);
  const { t } = useTranslation();
  const gridRef: any = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchContractListAPI()
    setRowData([])
    setSelectedDataRow([])
  }, [type])


  const fetchContractListAPI = async () => {
    setTrackLoadingState(true);
    try {
      const response = await getContractList(company, type, isToggled ? "all" : "default")
      const array2: string[] = [];
      if (response && response.errorCode === 0 && response.data) {
        setRowData(response.data.data);
        setTableConfiguration(response.data.tableDefinition);
        setEnableGridCustomization(response.data.customizeGridEnabled)
        setTableStorage(response.data.storageName)
        setTabSqlName(response.data.sqlName)
        setOption(response.data.menuName)
        setCommentValue('')
        const columnDef = [
          ...response.data.columnDefinitions,
          {
            "infoType": "column",
            "columnName": "actions",
            "columnType": "Char",
            "caption": "Actions",
            "defaultCaption": "Actions",
            "lastSavedCaption": "Actions",
            "alignment": "left",
            "defaultAlignment": "left",
            "lastSavedAlignment": "left",
            "columnIndex": response.data.columnDefinitions.length + 1,
            "defaultColumnIndex": response.data.columnDefinitions.length + 1,
            "lastSavedcolumnIndex": response.data.columnDefinitions.length + 1,
            "columnVisible": true,
            "defaultColumnVisible": true,
            "lastSavedColumnVisible": true,
            "numericFormat": null,
            "defaultNumericFormat": null,
            "lastSavedNumericFormat": null,
            "summaryFooterFunction": null,
            "defaultSummaryFooterFunction": null,
            "lastSavedSummaryFooterFunction": null,
            "currency": null,
            "defaultCurrency": null,
            "lastSavedcurrency": null,
            "colId": "action",
            "width": 150,
            "defaultWidth": 150,
            "lastSavedWidth": 150,
            "hide": false,
            "defaultHide": false,
            "lastSavedHide": false,
            "pinned": true,
            "defaultPinned": true,
            "lastSavedPinned": true,
            "pinnedPosition": "right",
            "defaultPinnedPosition": null,
            "lastSavedPinnedPosition": null,
            "sort": null,
            "defaultSort": null,
            "lastSavedSort": null,
            "defaultSortIndex": null,
            "lastSavedSortIndex": null,
            "aggFunc": null,
            "defaultAggFunc": null,
            "lastSavedAggFunc": null,
            "rowGroup": false,
            "defaultRowGroup": false,
            "lastSavedRowGroup": false,
            "rowGroupIndex": null,
            "defaultRowGroupIndex": null,
            "lastSavedRowGroupIndex": null,
            "pivot": false,
            "defaultPivot": false,
            "lastSavedPivot": false,
            "pivotIndex": null,
            "defaultPivotIndex": null,
            "lastSavedPivotIndex": null,
            "flex": 1,
            "defaultFlex": 1,
            "lastSavedFlex": 1,
            "amount": false,
            "lastRestoreCustomLayout": false,
          }
        ];

        setColumnDefs(columnDef)
      } else {
        toastMessage(response?.errorMsg, "error");
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."), "error");
    }
    finally {
      setTrackLoadingState(false);
    }
  }

  const setSeletedRow = (array) => {
    const srArray = array.map((item) => (item.seqNo));
    setSelectedDataRow(srArray);
  }

  const deleteBankDetailAction = async () => {
    try {
      const contractId = localStorage.getItem("deleteContractId");
      const response = await deleteContractDetails(company, { "notes": "", "seqNos": [contractId] });
      if (response && response.errorCode === 0) {
        toastMessage(t(response.successMsg), "success");
        setDeleteid(false);
        fetchContractListAPI();
      } else {
        toastMessage(t("An error occured Please try again later."), "error");
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."), "error");
    }
  }

  const openComment = (value) => {
    setCommentVisibilityFlag(true);
    setActionType(value);
  }

  const addRender = () => (
    <>
      {type === 'create' && (
        <div className="d-flex flex-wrap gap-4 align-items-center mr-3">
          <button
            className="btn text-white rounded-pill fs-6 primary-button"
            onClick={() => navigate('/editContractDetail', { state: { contractId: 0, previousPage: 'create' } })}
            title={t('New')}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#0a7eac",
              minWidth: "150px",
              flexShrink: 0, // Ensures button maintains size in small screens
            }}
          >
            <FaPlus style={{ marginRight: "5px" }} size={15} />
            {t('New')}
          </button>

          <ToggleButtonGroup
            color="primary"
            value={isToggled}
            exclusive
            onChange={() => setIsToggled((prev) => !prev)}
            aria-label="Platform"
            className="d-flex flex-grow-1 flex-wrap gap-1"
          >
            <ToggleButton value={false} sx={{ minWidth: 100, textAlign: "center" }}>
              Default
            </ToggleButton>
            <ToggleButton value={true} sx={{ minWidth: 100, textAlign: "center" }}>
              All Data
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}
    </>
  )

  const commentAdded = () => {
    setCommentVisibilityFlag(false)
    if(actionType === 'submitDetails') {
      onSubmitAction()
    }
    else if (actionType === 'approve') {
      onApproveAction()
    }
    // else if(actionType === 'create') {
    //   onIssueBatch()
    // }  else if (actionType === 'close') {
    //   onCloseAction()
    // } 
    else {
      onRejectAction()
    }
  }

  const onSubmitAction = async () => {
    try {
      setTrackLoadingState(true);
      const payload = {
        'notes': commentValue,
        'seqNos': selectedDataRow
      }

      const response = await submitContract(payload, company);
      if(response && response.errorCode === 0) {
        toastMessage(t(response.successMsg),"success");
        fetchContractListAPI();
      } else {
        if(response.errorMsg) {
          toastMessage(t(response.errorMsg),"error");

        } else {
          toastMessage(t("An error occured Please try again later."),"error");
        }
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  }

  const onApproveAction = async () => {
    try {
      setTrackLoadingState(true);
      const payload = {
        'notes': commentValue,
        'seqNos': selectedDataRow
      }

      const response = await approveContract(payload, company);
      if(response && response.errorCode === 0) {
        toastMessage(t(response.successMsg),"success");
        fetchContractListAPI();
      } else {
        if(response.errorMsg) {
          toastMessage(t(response.errorMsg),"error");

        } else {
          toastMessage(t("An error occured Please try again later."),"error");
        }
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  }

  const onRejectAction = async () => {
    try {
      setTrackLoadingState(true);
      const payload = {
        'notes': commentValue,
        'seqNos': selectedDataRow
      }

      const response = await rejectContract(payload, company);
      if(response && response.errorCode === 0) {
        toastMessage(t(response.successMsg),"success");
        fetchContractListAPI();
      } else {
        if(response.errorMsg) {
          toastMessage(t(response.errorMsg),"error");

        } else {
          toastMessage(t("An error occured Please try again later."),"error");
        }
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  }




   const renderContent = () => {
      return (
      <Formik
      initialValues={{ commentValue: commentValue}}
      onSubmit={(values) => commentAdded()}
    >
      {({ handleChange, handleBlur }) => (
        <Form>
          <Field
            as="textarea"
            name="description"
            placeholder="Comment"
            className="form-control"
            style={{ minHeight: '100px' }}
            value={commentValue}
            onChange={(e) => {
              setCommentValue(e.target.value);
              handleChange(e); // Important to call Formik's handleChange
            }}
            onBlur={handleBlur} // Ensure Formik's blur handling
          />
          <div className="col py-3">
          <button
            className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
            title={t('Submit')}
            type="submit"
            style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
            {t('Submit')}
          </button>
        </div>      
      </Form>
      )}
    </Formik>
      )
    }


  return (
    <>
      {loading && <CustomLoaders />
      }
      <div>
        <div
          className=" d-flex"
          style={{
            marginBottom: 15,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        ></div>
      </div>
      <KTCard className=" px-10">
        {trackLoadingState ? (<CustomLoadingCellRender />) : (
          <DevDynamicGridTable
            type={option}
            tabTitle={'Contract List'}
            tabSqlData={rowData}
            tabStorageData={columnDefs}
            tabStorage={tabStorage}
            tabSqlName={tabSqlName}
            tableConfiguration={tableConfiguration}
            tabId={'0'}
            fetchOthersTabData={fetchContractListAPI}
            customizeGridEnabled={enableGridCustomization}
            onSelectionChanged={(e) => setSeletedRow(e.selectedRowKeys)}
            exportFlag={false}
            extraAction={addRender}
          />
        )}
        <Row className="mt-5 mb-5 pt-15" style={{ display: "flex", justifyContent: "center" }}>
          {
            selectedDataRow && selectedDataRow.length > 0 &&
            <>
              <div className="col">
                <Row className="mt-5 mb-5" style={{ display: "flex", justifyContent: "center" }}>
                  {
                    type === 'create' && 
                    <button
                      className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-25 h-45px"
                      onClick={() => openComment('submitDetails')}
                      title={t('Submit')}
                      style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac" }}>
                      {t('Submit')}
                    </button>
                  }
                  {
                    type === 'approve' &&
                    (
                      <>
                       <div className="col-md-2">
                       <button
                      className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                      onClick={() => openComment('approve')}
                      title={t('Approve')}
                      style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
                      {t('Approve')}
                    </button>
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                        onClick={() => openComment('reject')}
                        title={t('Reject')}
                        style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac"}}>
                        {t('Reject')}
                      </button>
                    </div>
                      </>
                    )
                  }
                </Row>
              </div>
            </>
          }
        </Row>
        <Popup
        visible={commentVisibilityFlag}
        hideOnOutsideClick={true}
        onHiding={(e) => setCommentVisibilityFlag(false)}
        showCloseButton={true}
        resizeEnabled={true}
        title={'Comment'}
        width={335}
        height={280}
        contentRender={renderContent}/>
        {deleteid && (
          <DeleteModal
            title={t("Are you sure you want to delete this line(s)?")}
            onDelete={deleteBankDetailAction}
            onCancel={() => setDeleteid(false)}
          />
        )}
      </KTCard>

    </>
  )
}
