import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CustomLoaders from '../user/CustomLoaders';
import { Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton, Button } from '@mui/material';
import { toastMessage } from '../../modules/auth/functions/toastMessage';
import { useSelector } from 'react-redux';
import { getCostAllocation, getGLData, getJobCodeList, getLinkedCompanyList, getPhaseOptionByjobCode, getReqOptionByjobCode, getReqStockAllData, getStockStatus, getvendordropdown, rejectInvoice, saveCostAllocation, saveorUpdateInvoice, uploadAttachments, uploadInvoice, viewInvoice } from '../../modules/apps/user-management/users-list/core/_requests';
import ReactSelectStyle from "../../utils/ReactSelectStyle";
import Select from "react-select";
import * as Yup from 'yup';
import NumberBox from 'devextreme-react/number-box';
import { useAuth } from "../../modules/auth";
import { Column, DataGrid, DataGridRef, Editing, HeaderFilter, Lookup, SearchPanel, Selection, Summary, TotalItem, ValidationRule } from "devextreme-react/cjs/data-grid";
import { cleanNumber, formatPrice } from "../../utils/Config";
import CustomLoadingCellRender from "../user/CustomLoadingCellRender";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface LocationState {
  invoiceId?: any;
  previousPage?: any;
  invoiceAmount?: any;
  gstAmount?: any;
  currency?: any;
  invoiceNo?: any;
}

export default function InvoiceCostAllocation() {

  const location = useLocation();
  const { invoiceId } = (location.state as LocationState) || '';
  const { invoiceNo } = (location.state as LocationState) || '';
  const { previousPage } = location.state as LocationState || 'create';
  const { invoiceAmount } = (location.state as LocationState) || 0;
  const { gstAmount } = (location.state as LocationState) || 0;
  const { currency } = (location.state as LocationState) || null;
  const company = useSelector((state: any) => state?.userConfig?.company);
  const headerRef: any = useRef(null);
  const { currentUser } = useAuth();
  const [email, userId]: any = currentUser?.sub?.split("#");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [glAllocationList, setGLAllocationList] = useState([]);
  const [jobAllocationList, setJobAllocationList] = useState([]);
  const [companyOption, setCompanyOption] = useState<any>([]);
  const [selectedCompany, setSelectedComapany] = useState(company);
  const [allocationType, setAllocationType] = useState<'GL' | 'Job Cost'>('Job Cost');
  const [allocationArray, setAllocationArray] = useState<any>([]);
  const [tempGlData, setTempGlData] = useState<any>([]);
  const [tempJobData, setTempJobData] = useState<any>([]);
  const [prevAllocatedAmount, setPrevAllocatedAmount] = useState<number>(0);
  const [allocationAmount, setAllocationAmount] = useState(0);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedJobRowKeys, setSelectedJobRowKeys] = useState([]);
  const [selectedGlRowKeys, setSelectedGlRowKeys] = useState([]);
  const [phaseOption, setPhaseOption] = useState([]);
  const [reqOption, setReqOption] = useState([]);
  const [initialValue, setInitialValue] = useState({ allocationAmount: 0 })
  const [phaseCodeInitialized, setPhaseCodeInitialized] = useState('');
  const [reqCodeInitialized, setReqCodeInitialized] = useState('');
  const gridRef: any = useRef(null);

  const validationSchema = Yup.object({
    allocationAmount: Yup.number()
      .positive('Amount must be positive')
    // .required('Invoice Amount is required')
  });

  useEffect(() => {
    fetchCostAllocationData();
    fetchComapanyList();
  },[])

  useEffect(() => {
    fetchOptionList();
    fetchJobCodeList();
  }, [selectedCompany]);

  const fetchCostAllocationData = async () => {
    try {
      setLoading(true);
      const response = await getCostAllocation(invoiceId, company);
      if (response && response.actualErrorCode === 0) {
        if (response.data && response.data.length > 0) {
          const totalAmount = response.data.reduce((total, item) => total + item.amount, 0);
          setPrevAllocatedAmount(cleanNumber(totalAmount))
          setAllocationAmount(cleanNumber(invoiceAmount) - cleanNumber(totalAmount))
          setInitialValue(({ allocationAmount: Number((cleanNumber(invoiceAmount) - cleanNumber(totalAmount)).toFixed(2)) }))
        } else {
          setAllocationAmount(cleanNumber(invoiceAmount))
          setInitialValue(({ allocationAmount: Number(cleanNumber(invoiceAmount).toFixed(2)) }))
        }
      } else {
        toastMessage(t("An error occurred. Please try again later."), "error");
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const fetchOptionList = async () => {
    try {
      setLoading(true);
      const response = await getGLData(selectedCompany);
      if (response && response.data) {
        setGLAllocationList(response.data);
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const fetchComapanyList = async () => {
    try {
      setLoading(true);
      const response = await getLinkedCompanyList(company);
      if (response && response.data && response.data.length > 0) {
        const optionList = response.data.map((item) => ({
          value: item.code,
          label: item.name
        }));
        setCompanyOption(optionList);
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const fetchJobCodeList = async () => {
    try {
      setLoading(true);
      const response = await getJobCodeList(selectedCompany);
      if (response && response.data) {
        const list = response.data.map((item) => ({
          'jobCode': item.tenement,
          'description': item.name,
          'class jobCode': item.class
        }))
        setJobAllocationList(list);
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    }
    finally {
      setLoading(false);
    }
  }

  const handleIconClick = (data) => {
    const newObj = {
      id: allocationType + '-' + cleanNumber(allocationArray.length +1),
      seqNo: invoiceId,
      details: data.description,
      companyCode: selectedCompany,
      jobCode: allocationType !== 'GL' ? data?.jobCode : '',
      lineType: allocationType,
      accountCode: allocationType === 'GL' ? data?.glAccount : '',
      phaseCode: allocationType === 'GL' ? '' : phaseCodeInitialized,
      'reqCode jobCode': allocationType === 'GL' ? '' : reqCodeInitialized,
      amount: '',
      percentage: ''
    };

    setAllocationArray(allocationArray.concat([newObj]));

  }

  const handleAllocationTypeChange = (type) => {
    setAllocationType(type);
  };

  const resetAllValue = () => {
    setAllocationArray([]);
    setTempGlData([]);
    setTempJobData([]);
    setSelectedGlRowKeys([]);
    setSelectedJobRowKeys([]);
  }

  const updateAllocationRowData = (e: any) => {
    let updatedArray = allocationArray.map((element) =>
      e.key === element.id ? { ...element, ...e.data } : element
    );
  
    const updatedRowIndex = allocationArray.findIndex(
      (item) => item.id === e.key
    );
  
    // Check if phaseCode is being updated
    if (e.data.phaseCode && e.data.lineType === 'Job Cost') {
      const updatedPhaseCode = e.data.phaseCode;
      setPhaseCodeInitialized(updatedPhaseCode);
      updatedArray = updatedArray.map((row, index) => {
        // Update only rows below the updated row with blank phaseCode
        if (
          index > updatedRowIndex &&
          row.companyCode === e.data.companyCode &&
          row.lineType === 'Job Cost' &&
          !row.phaseCode
        ) {
          return { ...row, phaseCode: updatedPhaseCode };
        }
        return row;
      });
    }
  
    // Check if reqCode is being updated
    if (e.data.reqCode && e.data.lineType === 'Job Cost') {
      const updatedReqCode = e.data.reqCode;
      setReqCodeInitialized(updatedReqCode)
      updatedArray = updatedArray.map((row, index) => {
        // Update only rows below the updated row with blank reqCode
        if (
          index > updatedRowIndex &&
          row.companyCode === e.data.companyCode &&
          row.lineType === 'Job Cost' &&
          !row.reqCode
        ) {
          return { ...row, reqCode: updatedReqCode };
        }
        return row;
      });
    }
  
    setAllocationArray(updatedArray); // Update the DataGrid's dataSource
  };
  
  const equalDistribution = () => {
    if (allocationAmount === 0) {
      toastMessage(t("The allocation Amount can't be 0"), "waring");
    } else {
      const count = allocationArray.length;
      const perRowAmount = allocationAmount / count;
      const perRowpercentage = (perRowAmount / allocationAmount) * 100;
      const updateData = allocationArray.map((elememt) => ({
        'id': elememt.id,
        'jobCode': elememt.jobCode,
        'details': elememt.description,
        'companyCode': elememt.companyCode,
        'lineType': elememt['lineType'],
        'accountCode': elememt['accountCode'],
        'phaseCode': elememt.phaseCode,
        'reqCode': elememt['reqCode'],
        'amount': perRowAmount,
        'percentage': perRowpercentage
      }));
      setAllocationArray(updateData);
      toastMessage(t("Allocation Amount distributed equally"), "success");
    }
  }

  const percentageCalculation = () => {
    const updateData = allocationArray.map((elememt) => ({
      'id': elememt.id,
      'jobCode': elememt.jobCode,
      'companyCode': elememt.companyCode,
      'details': elememt.description,
      'lineType': elememt['lineType'],
      'accountCode': elememt['accountCode'],
      'phaseCode': elememt.phaseCode,
      'reqCode': elememt['reqCode'],
      'amount': (elememt.percentage * allocationAmount) * 0.01,
      'percentage': elememt.percentage
    }));
    setAllocationArray(updateData);
    toastMessage(t("Percantage recaluculated successfully"), "success");
  }

  const comapanyChangeAction = (value) => {
    setSelectedComapany(value)
    setReqCodeInitialized('')
    setPhaseCodeInitialized('')
    clearSearch()
  }

  const updatePhaseAndRequirementOption = async (e: any) => {
    if (e.column.dataField === 'phaseCode' || e.column.dataField === 'reqCode') {
      if (e.data.lineType === 'GL') {
        e.cancel = true;  // Disable editing if lineType is 'GL'
      } else {
        if (e.column.dataField === 'phaseCode') {
          try {
            const response = await getPhaseOptionByjobCode(e.data.companyCode, e.data.jobCode)
            if (response && response.errorCode === 0 && response.data && response.data.length > 0) {
              setPhaseOption(response.data);
            } else {
              setPhaseOption([]);
            }
          } catch (error) {
            toastMessage(t("An error occurred. Please try again later."), "error");
          }
          finally {
            setLoading(false);
          }
        } else {
          if (e.data.phaseCode != '' && e.column.dataField === "reqCode") {
            try {
              const response = await getReqOptionByjobCode(e.data.companyCode, e.data.jobCode, e.data.phaseCode)
              if (response && response.errorCode === 0 && response.data && response.data.length > 0) {
                setReqOption(response.data);
              } else {
                setReqOption([]);
              }
            } catch (error) {
              toastMessage(t("An error occurred. Please try again later."), "error");
              setLoading(false);
            }
            finally {
              setLoading(false);
            }
          } else {
            e.cancel = true;
          }
        }
      }
    }
    if (e.column.dataField === 'accountCode') {
      if (e.data.lineType === 'Job Cost') {
        e.cancel = true;  // Disable editing if lineType is 'Job Cost'
      }
    }
  }

  const modifyArray = (arr) => {
    return arr.map(item => {
      const { id, ...rest } = item;
      return {
        ...rest,
        seqNo: invoiceId
      };
    });
  };

  const saveCostAllocationData = async () => {
    try {
      const hasInvalidValues = allocationArray.some(
        (item) => !item.amount || isNaN(cleanNumber(item.amount))
      );

      if (hasInvalidValues) {
        toastMessage(t("Please enter a valid amount for all allocation data"), "error");
        return; // Stop execution if validation fails
      }
  
      // Validate "Job Cost" rows with empty phaseCode
      for (const row of allocationArray) {
        if (row.lineType === "Job Cost" && row.phaseCode === "") {
          toastMessage(t("Phase code And Requirement is mandatory for Job Cost type data"), "error");
          return; // Exit the function entirely
        }
        if (row.lineType === "Job Cost" && (!row.reqCode || row.reqCode.trim() === "")) {
          toastMessage(t("Requirement is mandatory for Job Cost type data"), "error");
          return; // Exit the function entirely
        }
      }
  
      // Calculate total amount
      const totalAmount = allocationArray.reduce(
        (total, item) => cleanNumber(total) + cleanNumber(item.amount),
        0
      ).toFixed(2);
      // Validate total amount
      if (totalAmount <= allocationAmount && totalAmount > 0) {
        setLoading(true);
        const payload = modifyArray(allocationArray);
  
        const response = await saveCostAllocation(payload, invoiceId, company, "save");
        if (response && response.actualErrorCode === 0) {
          toastMessage(t(response.successMsg), "success");
          navigate("/createInvoice", { state: { invoiceId, previousPage } });
        } else {
          if (response.errorMsg) {
            toastMessage(t(response.errorMsg), "error");
          } else {
            toastMessage(t("An error occurred. Please try again later."), "error");
          }
        }
      } else if (totalAmount < 0) {
        toastMessage(t("The Total amount can't be less than 0"), "error");
      } else {
        toastMessage(t("The Total amount allocated is more than the allocation amount"), "error");
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    } finally {
      setLoading(false);
    }
  };

  const onCellPrepared = (e) => {
    if (e.rowType === "data" && e.column.dataField === "phaseCode") {
      if (e.data.lineType === "Job Cost" && !e.data.phaseCode) {
        e.cellElement.style.backgroundColor = "#f8d7da"; // Light red
        e.cellElement.style.color = "#721c24"; // Dark red
        e.cellElement.title = "Phase code is mandatory for Job Cost type data"; // Tooltip
      }
    }
    if (e.rowType === "data" && e.column.dataField === "reqCode") {
      if (e.data.lineType === "Job Cost" && !e.data.reqCode) {
        e.cellElement.style.backgroundColor = "#f8d7da"; // Light red
        e.cellElement.style.color = "#721c24"; // Dark red
        e.cellElement.title = "Requirement is mandatory for Job Cost type data"; // Tooltip
      }
    }
  };
  
  const handleRowDeleted = (e: any) => {
    if (e.data.lineType === 'GL') {
      const updateTemp = tempGlData.filter((item) => (item.accountCode !== e.data.accountCode))
      const updateSelectedRows = selectedGlRowKeys.filter((item: any) => (item.code !== e.data.accountCode))
      setSelectedGlRowKeys(updateSelectedRows);
      setTempGlData(updateTemp)
    } else {
      const updateTemp = tempJobData.filter((item) => (item.jobCode !== e.data.jobCode))
      const updateSelectedRows = selectedJobRowKeys.filter((item: any) => (item.jobCode !== e.data.jobCode))
      setSelectedJobRowKeys(updateSelectedRows)
      setTempJobData(updateTemp)

    }
  }

  const clearSearch = () => {
    if (gridRef.current) {
      const gridInstance = gridRef.current.instance();
      gridInstance.searchByText(''); // Clears the search content
    }
  };

  const addVisibleRows = () => {
    if (gridRef.current) {
      const gridInstance = gridRef.current.instance(); // Access the DataGrid instance
  
      // Get visible rows
      const visibleRows = gridInstance.getVisibleRows();
  
      // Extract only the row data and push to an array
      const visibleRowData = visibleRows.map(row => {
        return {
          id: allocationType + '-' + cleanNumber(allocationArray.length + 1),
          seqNo: invoiceId,
          details: row.data?.description, // Accessing row.data since getVisibleRows() returns row metadata
          companyCode: selectedCompany,
          jobCode: allocationType !== 'GL' ? row.data?.jobCode : '',
          lineType: allocationType,
          accountCode: allocationType === 'GL' ? row.data?.glAccount : '',
          phaseCode: allocationType === 'GL' ? '' : phaseCodeInitialized,
          'reqCode jobCode': allocationType === 'GL' ? '' : reqCodeInitialized,
          amount: '',
          percentage: ''
        };
      });      
      console.log('Visible Row Data:', visibleRowData);
      setAllocationArray([...allocationArray, ...visibleRowData]);
    }
  };
 
  return (
    <>
      {loading && <CustomLoaders />}
      <div className="row h-100">
        {/* Left column: Labels and amounts */}
        <div className="col-sm-6 d-flex flex-column justify-content-start">
          {
            invoiceNo != '' &&
            <label htmlFor="genCat" className="fw-bold fs-6">
            {t('Invoice Number')}: {invoiceNo}
          </label>
          }
          <label htmlFor="genCat" className="fw-bold fs-6">
            {t('Invoice Amount')}: {currency} {formatPrice(cleanNumber(invoiceAmount) + cleanNumber(gstAmount))}
          </label>
          <label htmlFor="genCat" className="fw-bold fs-6">
            {t('Invoice Amount (Gst Excl.)')}: {currency} {formatPrice(cleanNumber(invoiceAmount))}
          </label>
          <label htmlFor="genCat" className="fw-bold fs-6">
            {t('GST Amount')}: {currency} {formatPrice(cleanNumber(gstAmount))}
          </label>
          <label htmlFor="genCat" className="fw-bold fs-6">
            {t('Allocated Amount')}: {currency} {formatPrice(cleanNumber(prevAllocatedAmount))}
          </label>
          <label htmlFor="genCat" className="fw-bold fs-6">
            {t('Balanced Amount')}: {currency} {formatPrice(cleanNumber(invoiceAmount) - cleanNumber(prevAllocatedAmount))}
          </label>
        </div>

        {/* Right column: Formik for Allocation Amount */}
        <div className="col-sm-6 d-flex flex-column align-items-end">
          <Formik
            enableReinitialize
            initialValues={initialValue}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={() => {}}
          >
            {({ errors, values, setFieldValue }) => (
              <Form>
                <div className="mb-4">
                  <label htmlFor="allocationAmount" className="fw-bold fs-6 mb-2">
                    {t('Allocation Amount')}
                  </label>
                  <Field name="allocationAmount">
                    {({ field, form }) => (
                      <NumberBox
                        format="#,##0.00"
                        value={cleanNumber(values.allocationAmount) || 0}
                        placeholder={t('Allocation Amount')}
                        stylingMode="outlined"
                        height="40px"
                        onValueChanged={(e) => {
                          if (e.value <= Number((Number(cleanNumber(invoiceAmount)) - Number(cleanNumber(prevAllocatedAmount))).toFixed(2))) {
                            setFieldValue('allocationAmount', e.value);
                            setInitialValue({ allocationAmount: e.value });
                            setAllocationAmount(e.value);
                          } else {
                            toastMessage(
                              t("The Allocated Amount can't be greater than the Balanced Amount."),
                              "error"
                            );
                          }
                        }}
                      />
                    )}
                  </Field>
                  {errors?.allocationAmount && (
                    <div
                      style={{
                        color: '#f35a7f',
                        marginTop: '5px',
                        fontSize: '11px',
                        fontWeight: '500',
                      }}
                    >
                      {errors?.allocationAmount}
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
          <div className="mb-4">
            <label htmlFor="companySelect" className="fw-bold fs-6 me-2">
              {t('Company')}
            </label>
            <div style={{ zIndex: 999999999 }}>
              <Select
                options={companyOption}
                onChange={(selectedOption) => comapanyChangeAction(selectedOption.value)}
                classNamePrefix="custom-select"
                value={
                  companyOption.find((option) => option.value === selectedCompany) || null
                }
                styles={ReactSelectStyle}
                placeholder={t('Select Company')}
              />
            </div>
          </div>
        </div>

        <Row className="mb-2">
                <div className="col-md-3 mt-3">
                  <button
                    className="btn text-white rounded-pill text-wrap primary-button w-100 h-45px"
                    onClick={() => allocationArray && allocationArray.length > 0 ? equalDistribution() : ''}
                    title={t('Equal Distribution')}
                    type="button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#0a7eac",
                    }}
                  >
                    {t('Equal Distribution')}
                  </button>
                </div>
                <div className="col-md-3 mt-3">
                  <button
                    className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                    onClick={() => allocationArray && allocationArray.length > 0 ? percentageCalculation() : ''}
                    title={t('Percentage Recalculation')}
                    type="button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#0a7eac",
                    }}
                  >
                    {t('Percentage Recalculation')}
                  </button>
                </div>
                <div className="col-md-2 mt-3">
                  <button
                    className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                    onClick={() => allocationArray && allocationArray.length > 0 ? saveCostAllocationData() : ''}
                    title={t('Save')}
                    type="button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#0a7eac",
                    }}
                  >
                    {t('Save')}
                  </button>
                </div>
                <div className="col-md-2 mt-3">
                  <button
                    className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                    onClick={() => navigate('/createInvoice', { state: { invoiceId, previousPage } })}
                    title={t('Cancel')}
                    type="button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#0a7eac",
                    }}
                  >
                    {t('Cancel')}
                  </button>
                </div>
                <div className="col-md-2 mt-3">
                  <button
                    className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                    onClick={() => resetAllValue()}
                    title={t('Clear')}
                    type="button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#0a7eac",
                    }}
                  >
                    {t('Clear')}
                  </button>
                </div>
              </Row>
              <Row className="mt-6 mb-6">
              <Col className="d-flex justify-content-around">
                {
                  allocationArray && allocationArray.length === 0 &&
                  <Button
                    style={{ width: "150px" }}
                    size="large"
                    type={'button'}
                    variant={'contained'}
                    onClick={() => navigate('/createInvoice', { state: { invoiceId, previousPage } })}>
                    {t('Back')}
                  </Button>
                }
                <Button
                  style={{ width: "150px" }}
                  size="large"
                  type={'button'}
                  variant={allocationType === 'GL' ? 'contained' : 'outlined'}
                  onClick={() => handleAllocationTypeChange('GL')}
                >
                  {t('GL')}
                </Button>
                <Button
                  style={{ width: "150px" }}
                  size="large"
                  type={'button'}

                  variant={allocationType === 'Job Cost' ? 'contained' : 'outlined'}
                  onClick={() => handleAllocationTypeChange('Job Cost')}
                >
                  {t('Job Cost')}
                </Button>

              </Col>
            </Row>

            <div className={`col-md-4`} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            {/* Top row with four divs aligned equally */}
            
            {
              (allocationType === 'GL' && glAllocationList && glAllocationList.length > 0) || (allocationType != 'GL' && jobAllocationList && jobAllocationList.length > 0) ? 
                <DataGrid showBorders={true} ref={gridRef} dataSource={allocationType === 'GL' ? glAllocationList : jobAllocationList} >

                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={240} placeholder="Search..." />

                  <Column dataField={allocationType === "GL" ? "glAccount" : "jobCode"} caption={allocationType === "GL" ? "Code" : "Job Code"} width="130" allowSorting={false}
                  headerCellRender={() => (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                      <IconButton size="small" onClick={addVisibleRows}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                      <span style={{ marginLeft: '8px' }}>{allocationType === "GL" ? "Code" : "Job Code"}</span>
                    </div>
                  )}
                  cellRender={({ data }) => (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                       <IconButton size="small" onClick={() => handleIconClick(data)}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      <span>{allocationType === "GL" ? data.glAccount : data.jobCode}</span>
                    </div>
                )}/>
                <Column dataField="description" caption="Description" width="150"/>
                <Column dataField={allocationType === "GL" ? "classCode" : "class jobCode"} caption="Class" width="80"/>
                
                </DataGrid> : <CustomLoadingCellRender/> 
              //   :
              //   jobAllocationList && jobAllocationList.length > 0 ?
              //   <DataGrid
              //     showBorders={true}
              //     dataSource={jobAllocationList}
              //   >
              //   <HeaderFilter visible={true} />
              //   <SearchPanel visible={true} width={240} placeholder="Search..." />
              
              //   <Column dataField="jobCode" caption="Job Code" width="130" allowSorting={false}
              //   headerCellRender={() => (
              //     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              //       <IconButton size="small" onClick={() => ''}>
              //         <AddCircleOutlineIcon />
              //       </IconButton>
              //       <span style={{ marginLeft: '8px' }}>Job Code</span>
              //     </div>
              //   )}
              //     cellRender={({ data }) => (
              //       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              //          <IconButton size="small" onClick={() => handleIconClick(data)}>
              //             <AddCircleOutlineIcon />
              //           </IconButton>
              //         <span>{data.jobCode}</span>
              //       </div>
              //   )}/>
              //   <Column dataField="description" caption="Description" width="150"/>
              //   <Column dataField="class jobCode" caption="Class" width="80"/>
              // </DataGrid> : <CustomLoadingCellRender/>
            }

          </div>
          {allocationArray && allocationArray.length > 0 && (
            <div className="col-md-8 mt-20">
              
              <DataGrid
                showBorders={true}
                keyExpr="id"
                onRowUpdated={updateAllocationRowData}
                onRowRemoved={(e) => handleRowDeleted(e)}
                onEditingStart={updatePhaseAndRequirementOption}
                dataSource={allocationArray}
                onCellPrepared={onCellPrepared}
                onEditorPreparing={(e) => {
                  if (e.parentType === 'dataRow' && (e.dataField === 'phaseCode' || e.dataField === 'reqCode')) {
                    e.editorOptions = {
                      ...e.editorOptions,
                      dropDownOptions: {
                        width: 300, // Set custom dropdown width
                      },
                    };
                  }
                }}
              >
                <HeaderFilter visible={true} />
                <SearchPanel visible={false} width={240} placeholder="Search..." />

                 <Column
                  type="buttons"
                  width={80}
                  buttons={[
                    {
                      name: 'delete',
                      icon: 'trash',
                      onClick: (e: any) => {
                        handleRowDeleted({ data: e.row.data });
                        e.component.deleteRow(e.row.rowIndex); // Delete the row programmatically
                      },
                    },
                  ]}
                  caption="Actions"
                />

                <Column dataField="companyCode" caption="Company Code" width={100} allowEditing={false} />
                <Column dataField="jobCode" caption="Job Code" width={100} allowEditing={false} />
                <Column
                  dataField="phaseCode"
                  caption="Phase"
                  width={150}
                  calculateDisplayValue={"phaseCode"}
                >
                  <Lookup
                    dataSource={phaseOption}
                    displayExpr={(item) =>
                      item ? `${item.phaseCode} - ${item.description}` : ''
                    }
                    valueExpr="phaseCode"
                  />
                </Column>
                <Column
                  dataField="reqCode"
                  caption="Requirement"
                  width={150}
                  calculateDisplayValue={"reqCode"}
                >
                  <Lookup
                    dataSource={reqOption}
                    displayExpr={(item) =>
                      item ? `${item.reqCode} - ${item.description}` : ''
                    }
                    valueExpr="reqCode"
                  />
                </Column>
                <Column dataField="accountCode" caption="Gl Account" width={150} allowEditing={false} />
                <Column dataField="amount" caption="Amount" format="#,##0.00" width={150} dataType="number">
                  <ValidationRule type="numeric" />
                </Column>
                <Column
                  dataField="percentage"
                  caption="Percentage"
                  format="##0.00"
                  width={150}
                  dataType="number"
                >
                  <ValidationRule type="numeric" />
                  <ValidationRule type="range" min={0} max={100} message="Percentage must be between 0 and 100" />
                </Column>

                <Editing mode="cell" allowUpdating={true} allowDeleting={true} confirmDelete={false}/>

                <Summary>
                  <TotalItem
                    column="amount"
                    summaryType="sum"
                    displayFormat="Total: {0}"
                    valueFormat="#,##0.00"
                  />
                  <TotalItem
                    column="percentage"
                    summaryType="sum"
                    displayFormat="Total: {0}%"
                    valueFormat={"##.00"}
                  />
                </Summary>
              </DataGrid>

            </div>
          )}


      </div>


    </>
  )
}

