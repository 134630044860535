import purchaseorder from "../assets/demo/purchaseOrder.png";
import supplier from "../assets/demo/supplier_portal.png";
import service from "../assets/demo/service management.png";
import knowledge from "../assets/demo/knowledge management.png";
import inventory from "../assets/demo/Inventory management.png";
import invoice from "../assets/demo/invoiceIcon.png";

export const sidebarMenuItem = [
  {
    application: "Purchase Orders",
    items: [
      {
        menu_item: "PO Enquiry",
        textDisplayOnFunctionTile: "Search and view PO related data",
        MNI_NAME: "MNI_RepExecutor",
        items: [],
      },
      {
        menu_item: "PO Contract Maintenance",
        MNI_NAME: "MNI_Setup_POContrk",
        items: [],
      }
    ],
  },
  {
    application: "Plant Maintenance",
    items: [
      {
        menu_item: "Work Request",
        textDisplayOnFunctionTile:
          "Create,Approve and convert Maintenance Work Request",
        items: [
          {
            menu_item: "Create Work Request",
            MNI_NAME: "MNI_Plant_Inquiry",

            items: [],
          },
          {
            menu_item: "Approve Work Request",
            MNI_NAME: "MNI_Plant_Inquiry",
            items: [],
          },
          {
            menu_item: "Past work Request",
            MNI_NAME: "RequestProcess",
            items: [],
          },
        ],
      },
      {
        menu_item: "Plant Inquiry",
        textDisplayOnFunctionTile:
          "Plant Inquiry",
        items: [],
      }
    ],
  },
  {
    application: "Requisition",
    items: [
      {
        menu_item: "Purchase Request",
        textDisplayOnFunctionTile: "Purchase Request, Approvals and Processing",
        items: [
          {
            menu_item: "Create Purchase Request",
            MNI_NAME: "MNI_PMaint",
            items: [],
          },
          {
            menu_item: "Approve Purchase Request",
            MNI_NAME: "MNI_Authorization",
            items: [],
          },
          {
            menu_item: "Approved/Rejected Request Listing",
            MNI_NAME: "MNI_REP_RQList",
            items: [],
          },
        ],
      },
    ],
  },
  {
    application: "System Module",
    items: [
      {
        menu_item: "User Maintenance",
        textDisplayOnFunctionTile:
          "Create and edit user and user access to the system",
        items: [
          {
            menu_item: "User List",
            MNI_NAME: "MNI_UserID",
            applCode: "SY",
            items: [],
          },
          {
            menu_item: "Add New User",
            MNI_NAME: "MNI_UserID",
            applCode: "SY",
            items: [],
          },
        ],
      },
    ],
  },
  {
    application: "Inventory",
    items: [
      {
        menu_item: "Stock Request",
        textDisplayOnFunctionTile: "Create,Approve and convert stock request",
        items: [
          {
            menu_item: "Create Stock Request",
            MNI_NAME: "MNI_StockReq_Maintenance",
            items: [],
          },
          {
            menu_item: "Approve Stock Request",
            MNI_NAME: "MNI_StockReq_Authorisation",
            items: [],
          },
          {
            menu_item: "Convert Stock Request into Issue batch",
            MNI_NAME: "MNI_StockReq_IssueBatch",
            items: [],
          },
          {
            menu_item: "Close Unprocessed Stock Request",
            MNI_NAME: "MNI_StockReq_Close",
            items: [],
          },
        ],
      },
      {
        menu_item: "Stock Enquiry",
        textDisplayOnFunctionTile: "Search and view Stock item related data",
        items: [],
        MNI_NAME: "MNI_Profile_InvInquiry",
      },
    ],
  },
  {
    application: "Accounts Payable",
    items: [
      {
        menu_item: "Invoice Register",
        textDisplayOnFunctionTile: "Register / Upload new Invoice",
        MNI_NAME: "MNI_DocManagement_InvReg",
        items: []
      },
      {
        menu_item: "Vendor Bank Approval",
        textDisplayOnFunctionTile: "",
        MNI_NAME: "MNI_Vendor_Bank",
        items: []
      },
    ],
  }
];
export const config = {
  autoLogoutTime: 20 * 60 * 1000  
};
export const schema = [
  { name: "Purchase Orders", icon: purchaseorder },
  { name: "Plant Maintenance", icon: supplier },
  { name: "Requisition", icon: service },
  { name: "System Module", icon: knowledge },
  { name: "Inventory", icon: inventory },
  { name: "Accounts Payable", icon: invoice},
  { name: "Bank Approval Card", icon: invoice},
  { name: "Plant Inquiry", icon: supplier}
];
export const pathSchema = [
  {
    name: "Add New User",
    to: "createuser",
    textDisplay: "Create a new user",
  },
  {
    name: "User Listing ",
    to: "system/users",
    textDisplay: "Review and edit users",
  },
  {
    name: "Create Stock Request",
    to: "stock",
    textDisplay: "View and create stock request",
  },
  {
    name: "Approve Stock Request",
    to: "approve-stock-request",
    textDisplay: "Approve pending stock request",
  },
  {
    name: "Convert Stock Request into Issue batch",
    to: "create-issue-batch",
    textDisplay: "Print picking slip and issue stock from warehouse",
  },
  {
    name: "Close Unprocessed Stock Request",
    to: "close-unprocessed-stock",
    textDisplay: "Remove unactioned authorised requests",
  },
  {
    name: "PO Enquiry",
    to: "po-enquiry",
    textDisplay: "",
  },
  {
    name: "Stock Enquiry",
    to: "stock-enquiry",
    textDisplay: "",
  },
  {
    name: "Create Work Request",
    textDisplay: "enter a new work request",
  },
  {
    name: "Approve Work Request",
    textDisplay: "Approve Work Request",
  },
  
  {
    name: "Past Work Request",
    textDisplay: "Convert to a work order",
  },
  {
    name: "Create Invoice entry",
    to: 'invoice',
    textDisplay:"Register / Upload new Invoice"
  },
  {
    name: "Invoice approval",
    to: 'approveInvoice',
    textDisplay:"Approve Invoice"
  },
  {
    name: "Invoice Batch Creation",
    to: 'createInvoiceBatch',
    textDisplay:"Create Invoice Batch for Approval Invoice"
  },
  {
    name: "Close Invoice entry",
    to: 'unprocessedInvoice',
    textDisplay:"Close Unprocessed Invoice"
  },
  {
    name: "Update Bank Details",
    to: 'bankDetailList',
    textDisplay:""
  },
  {
    name: "Approve Bank Details",
    to: 'bankApprovalList',
    textDisplay:""
  },
  {
    name: "Plant Inquiry",
    to: 'plantEnquiry',
    textDisplay:""
  },
  {
    name: "Create Contract",
    to: 'contractList',
    textDisplay: ""
  },
  {
    name: "Create Invoice entry",
    to: 'invoice',
    textDisplay:"Register / Upload new Invoice"
  },
  {
    name: "Approve Contract",
    to: 'approveContract',
    textDisplay: ""
  }
];

export const descSchema = [
  {
    Function: "PO Enquiry",
    textDisplay: "Search and view PO related data",
  },
  {
    Function: "Work Request",
    textDisplay: "Create, Approve and convert Maintenance Work Request",
  },
  {
    Function: "Purchase Request",
    textDisplay: "Purchase Request, Approvals and Processing",
  },
  {
    Function: "User Maintenance",
    textDisplay: "Create and edit user and user access to the system",
  },
  {
    Function: "Stock Request",
    textDisplay: "Create, Approve and convert stock request",
  },
  {
    Function: "Stock Enquiry",
    textDisplay: "Search and view Stock item related data",
  },
  {
    Function: "Invoice Register",
    textDisplay: "Upload invoices for approval",
  },
  {
    Function: "Vendor Bank Approval",
    textDisplay: "Change Vendor Bank details",
  },
  {
    Function: "Plant Inquiry",
    textDisplay: "Create, Approve and convert Maintenance Work Request",
  },
];
