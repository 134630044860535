import React, { useEffect, useRef, useState } from 'react'
import CustomLoaders from '../user/CustomLoaders';
import { colors, titleCss } from '../../../constants/colors';
import clsx from 'clsx';
import { Field, Form, Formik } from 'formik';
import { Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth } from '../../modules/auth';
import Select from "react-select";
import ReactSelectStyle from "../../utils/ReactSelectStyle";
import { IconButton, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadFile from '../../modules/custom components/uploadFile';
import List from 'devextreme-react/list';
import CheckBox from 'devextreme-react/cjs/check-box';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { Popup } from 'devextreme-react/popup';
import { format, parse, isDate } from 'date-fns';
import SelectInput from "../../utils/SelectInput";
import { getAuthGroupForInvoice, getCurrencyOption, getStockStatus, getvendordropdown, getAuthGroupby, getStockListForContract, getContractDetail, getContractAttachments, saveContractAttachment, deleteContractAttachment, getDoc, saveContractDetails, approveContract, rejectContract } from '../../modules/apps/user-management/users-list/core/_requests';
import { toastMessage } from '../../modules/auth/functions/toastMessage';
import NumberBox from 'devextreme-react/number-box';
import { cleanNumber } from '../../utils/Config';
import { KTCard } from '../../../theme/helpers/components/KTCard';
import CustomLoadingCellRender from '../user/CustomLoadingCellRender';
import DevDynamicGridTable from '../PO-Stock-Enquiry/DevDynamicGridTable';
import { Column, DataGrid, DataGridRef, Editing, HeaderFilter, Lookup, SearchPanel, Selection, Summary, TotalItem, Toolbar, Item } from "devextreme-react/cjs/data-grid";
import 'devextreme/dist/css/dx.material.blue.light.css';

import * as Yup from 'yup';


interface LocationState {
  contractId?: any;
  isViewOnly?: any;
  previousPage?: any;
}

export default function EditContactDetails() {

  const { t } = useTranslation();
  const location = useLocation();
  const { contractId: initialContractId } = (location.state as LocationState) || {};
  const { isViewOnly } = location.state as LocationState || false;
  const { previousPage } = location.state as LocationState || 'create';
  const company = useSelector((state: any) => state?.userConfig?.company);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [userEmail, userId, userName]: any = currentUser?.sub?.split("#");
  const headerRef: any = useRef(null);

  const fileExtensions = ['.jpg', '.jpeg', '.png', '.pdf'].join(',');

  const validationSchema = Yup.object().shape({
    contractCode: Yup.string()
      .required('Contract Code is required'), // Set as required
      contractStartDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)), // Allows empty values
    contractEndDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('contractStartDate', (contractStartDate, schema) => 
        contractStartDate ? schema.min(contractStartDate, 'End Date cannot be before Start Date') : schema
      ),
  });

  const gridRef: any = useRef(null);
  

  const [contractId, setContractId] = useState(initialContractId);
  const [loading, setLoading] = useState(false)
  const [attachmentList, setAttachmentList] = useState([])
  const [selectedAttachmentArray, setSelectedAttachmentArray] = useState<any>([])
  const [uploadFileVisibility, setUploadFileVisibility] = useState<boolean>(false)
  const [selectedFiles, setSelectedFiles] = useState<any>([])
  const [vendorOption, setVendorOption] = useState<any>([]);
  const [vendorCodeNameMapper, setVendorCodeNameMapper] = useState<any>([]);
  const [currencyOption, setCurrencyOption] = useState<any>([])
  const [statusOption, setStatusOption] = useState<any>([]);
  const [authgroupOption, setAuthgroupOption] = useState<any>([]);
  const [authorizedByOption, setAuthorizedByOption] = useState<any>([]);
  const [approvalTypeOption, setApprovalTypeOption] = useState<any>([])
  const [authGroupCode, setAuthGroupCode] = useState('');
  const [readAuthGroupFlag, setReadAuthGroupFlag] = useState(false);
  const [readAuthByFlag, setReadAuthByFlag] = useState(false);
  const [trackLoadingState, setTrackLoadingState] = useState(false);
  const [submitComment, setSubmitComment] = useState(false)
  const [stockListingData, setStockListingData] = useState([])
  const [commentValue, setCommentValue] = useState('')

  const [trackStockLoadingState, setTrackStockLoadingState] = useState(false)
  const [selectedStockRows, setSelectedStockRows] = useState([]);
  const [preselectedRows, setPreselectedRows] = useState([]);

  const [option, setOption] = useState([]);
  const [poRowData, setPoRowData] = useState([])
  const [poColumnDefs, setpoColumnDefs] = useState([])
  const [poTabStorage, setPoTabStorage] = useState([])
  const [poTabSqlName, setPoTabSqlName] = useState([])
  const [poTableConfiguration, setPoTableConfiguration] = useState([])
  const [poSeletedRow, setPoSeletedRow] = useState([])

  const [stockListFlag, setStockListFlag] = useState(false)
  const [warningFlag, setWarningFlag] = useState(false)
  const [actionType,setActionType] = useState('')

  const [initialValue, setInitialValue] = useState({
    contractCode: '',
    contractDetails: '',
    vendorCode: '',
    vendorName: '',
    vendorEmail: '',
    contractStartDate: '',
    contractEndDate: '',
    amountCurrency: '',
    contractAmount: '',
    commitedAmount: '',
    remainingAmount: '',
    seqNo: '',
    linkToStockSystem: false,
    registrationRef: '',
    additionalDetails: '',
    registeredAuthority: false,
    authorityRegistrationDate: '',
    requestedBy: '',
    authGroup: '',
    approvalType: '',
    authorizeBy: '',
    authHistory: '',
    authorityName: '',
    statusDesc:'',
    notes: ''
  });

  useEffect(() => {
    if (contractId !== 0) {
      fetchContractDetails()
      fetchContractAttacmentList()
    }
    fetchCurrency()
    fetchStatus()
    fetchVendorDetails()
    fetchAuthGroup()
    fetStockList()
    setCommentValue('')
  }, [contractId])

  const fetchContractAttacmentList = async () => {
    try {
      setLoading(true)
      const response = await getContractAttachments(company, contractId);
      console.log("getContractAttachments response:-", response)
      if (response && response.actualErrorCode === 0) {
        setAttachmentList(response.data)
      }
      setLoading(false)
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const fetchContractDetails = async () => {
    try {
      setLoading(true)
      const response = await getContractDetail(company, contractId);
      console.log("getContractDetail response:-", response)
      if (response && response.data) {
        setInitialValue(response.data)
        setSelectedStockRows(response.data.stockItems)
        setPreselectedRows(response.data.stockItems.map(item => item.Code))

      }
      setLoading(false)
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const fetchVendorDetails = async () => {
    try {
      setLoading(true);
      const response = await getvendordropdown(company);
      if (response) {
        setVendorCodeNameMapper(response?.data.data);
        const transformedGroup = response?.data.data.map((item) => ({
          value: item?.code,
          label: `${item?.code} - ( ${item?.name} )`,
          currency: item?.currency,
          email: item?.email
        }));
        setVendorOption(transformedGroup);
      } else {
        setVendorOption([]);
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const fetchCurrency = async () => {
    try {
      setLoading(true);
      const response = await getCurrencyOption();
      if (response && response.data) {
        const currencyData = response.data.map(item => ({
          label: item.currencyName,
          value: item.currencyCode
        }));
        setCurrencyOption(currencyData);
      } else {
        toastMessage(response.errorMsg, "error");
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const fetchAuthGroup = async () => {
    try {
      setLoading(true)
      const authgroupData = await getAuthGroupForInvoice(company, initialValue.requestedBy);
      if (Array.isArray(authgroupData.data)) {
        const transformedAuthGroup = authgroupData?.data.map((item) => ({
          value: item?.authGroup,
          label: `${item?.authGroup} - ${item?.authDetail}`,
          authType: item?.authType,
        }));

        const transformApprovalType = authgroupData?.data.map((item) => ({
          value: item?.authType,
          label: item?.authTypeName
        }))

        setAuthgroupOption(transformedAuthGroup);
        setApprovalTypeOption(transformApprovalType);
      } else {
        setAuthgroupOption([]);
        setApprovalTypeOption([])
      }
      setLoading(false)
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  };


  const fetchStatus = async () => {
    try {
      setLoading(true);
      const response = await getStockStatus(company);
      if (response && response.data) {
        const convertedData = response.data.map(item => ({
          label: item.description,
          value: item.code
        }));
        setStatusOption(convertedData);
      } else {
        toastMessage(response.errorMsg, "error");
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      const formData = new FormData();
      const data = values.action === "submit" 
      ? { ...values, comments: commentValue } 
      : values;
      console.log("Akash test:-",selectedStockRows)
      const payload = { ...data, stockItems: selectedStockRows }
      const response = await saveContractDetails(company, payload);
      if (response && response.errorCode === 0) {
        if (contractId === 0) {
          setContractId(response.data.seqNo)
        }
        if (previousPage === 'create') {
          if (values.action === 'save') {
            fetchContractDetails()
          } else {
            navigate('/contractList');
          }
          toastMessage(response.successMsg, "success");
        }
        else {
          if (actionType == 'approve') {
            approveContact()
          } else if (actionType == 'reject') {
            rejectAction()
          } else {
            navigate('/approveContract')
          }
        }

      } else {
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");
        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later handleSubmit."), "error");
    }
    finally {
      setLoading(false)
    }
  }

  const approveContact = async () => {
    try {
      setTrackLoadingState(true);
      const payload = {
        'notes': commentValue,
        'seqNos': [contractId]
      }

      const response = await approveContract(payload, company);
      if(response && response.errorCode === 0) {
        toastMessage(t(response.successMsg),"success");
          navigate('/approveContract')
      } else {
        if(response.errorMsg) {
          toastMessage(t(response.errorMsg),"error");

        } else {
          toastMessage(t("An error occured Please try again later."),"error");
        }
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  }

  const rejectAction = async () => {
    try {
      setTrackLoadingState(true);
      const payload = {
        'notes': commentValue,
        'seqNos': [contractId]
      }

      const response = await rejectContract(payload, company);
      if(response && response.errorCode === 0) {
        toastMessage(t(response.successMsg),"success");
          navigate('/approveContract')
      } else {
        if(response.errorMsg) {
          toastMessage(t(response.errorMsg),"error");

        } else {
          toastMessage(t("An error occured Please try again later."),"error");
        }
      }
    } catch (error) {
      toastMessage(t("An error occured Please try again later."),"error");
    }
    finally{
      setTrackLoadingState(false);
    }
  }


  const removeAttachment = async (id) => {
    try {
      setLoading(true);
      const response = await deleteContractAttachment(company, { "contractCode": initialValue.contractCode, "lineNos": [id] });
      if (response && response.errorCode === 0) {
        fetchContractAttacmentList()
      } else {
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");
        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const onSelectedFilesChanged = (e) => {
    setSelectedFiles(e.value);
  }

  const uploadFileAction = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("companyCode", company);
      formData.append("seqNo", contractId);

      if (selectedFiles && selectedFiles.length > 0) {
        selectedFiles.forEach(file => {
          formData.append("attachments", file);
        });
      }
      const response = await saveContractAttachment(formData);
      if (response && response.errorCode === 0 && response.data) {
        setAttachmentList(response.data);
        setSelectedFiles([]);

      } else {
        toastMessage(response.errorMsg, "error")
      }
      setUploadFileVisibility(false);
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  };

  const deleteSelectedAttachment = async () => {
    try {
      setLoading(true);
      const payload = {
        contractCode: initialValue.contractCode,
        lineNos: selectedAttachmentArray
      }
      const response = await deleteContractAttachment(company, payload);
      if (response && response.errorCode === 0) {
        setSelectedAttachmentArray([])
        fetchContractAttacmentList()
      } else {
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");
        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const attachmentView = (item) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {!isViewOnly ? (
        <div>
          <CheckBox
            defaultValue={false}
            onValueChanged={(e) => attachmentCheckBoxAction(e.value, item.lineNo)}
            text={item.name}
          />
        </div>
      ) : (item.name)}
      <div>
        <IconButton aria-label="view" size="large" onClick={() => viewAttachment(item)}>
          <VisibilityIcon fontSize="inherit" />
        </IconButton>
        {!isViewOnly && (
          <IconButton aria-label="delete" size="large" onClick={() => removeAttachment(item.lineNo)}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        )}
      </div>
    </div>
  );
  

  const attachmentCheckBoxAction = (value, id) => {
    if (value) {
      setSelectedAttachmentArray(prevArray => [...prevArray, id]);
    } else {
      setSelectedAttachmentArray(selectedAttachmentArray.filter((element) => element !== id && (element)));
    }
  }

  const clearSearch = () => {
    if (gridRef.current) {
      const gridInstance = gridRef.current.instance();
      gridInstance.searchByText(''); // Clears the search content
    }
  };

  const viewAttachment = async (obj) => {
    try {
      setLoading(true);
      const response = await getDoc(obj.path);
      const contentType = response.headers['content-type'];

      if (response.status === 200) {
        // Ensure response.data is a buffer or array buffer
        const data = new Blob([response.data], { type: contentType });
        const url = URL.createObjectURL(data);
        window.open(url, '_blank');
      } else {
        toastMessage(t("An error occurred. Please try again later."), "error");
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
    } finally {
      setLoading(false);
    }
  }


  const fetchAuthorizedByOption = async (value) => {
    try {
      const response = await getAuthGroupby(company, value);
      if (Array.isArray(response.data)) {
        const transformedGroup = response?.data.map((item) => ({
          value: item?.authorizeBy,
          label: `${item?.authorizeByName}`,
        }));
        setAuthorizedByOption(transformedGroup);
      } else {
        setAuthorizedByOption([]);
      }
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later."), "error");
      setLoading(false);
    }
  }

  const handleRowDeleted = (e: any) => {
    const updateSelectedRows = selectedStockRows.filter((item: any) => (item.Code !== e.data.Code))
    const updatePreSelectedRows = preselectedRows.filter((item: any) => (item !== e.data.Code))
    setSelectedStockRows(updateSelectedRows)
    setPreselectedRows(updatePreSelectedRows)
  }

  
  const renderStockView = () => {
    return (
      <>
        {trackStockLoadingState ? (<CustomLoadingCellRender />) : (
          <div style={{ flex: 1 }}>
            <DataGrid
              ref={gridRef}
              dataSource={stockListingData}
              showBorders={true}
              keyExpr="Code"
              allowColumnResizing={true}
              columnResizingMode="widget"
              allowColumnReordering={true}
              selection={{ mode: "multiple", showCheckBoxesMode: "always" }}
              width="100%"
              onSelectionChanged={handleSelectionChange}
              selectedRowKeys={preselectedRows} // Pass your array of selected row keys here
              onSelectedRowKeysChange={(keys: any) => setPreselectedRows(keys)} // Keep track of selected rows            
              headerFilter={{ visible: true }}>
              <SearchPanel visible={true} width={240} placeholder="Search..." />
              <Column caption="Code" dataField="Code" width={100} />
              <Column caption="Stock Type" dataField="Stock Type" width={200} />
              <Column caption="Inventory code" dataField="Inv Code" width={200} />
              <Column caption="On Hand Qty" dataField="OnHandQty" width={100} allowEditing={false} />
              <Column caption="Order Qty" dataField="OrderQty" width={100} allowEditing={false} />
              <Column caption="OEM ID" dataField="OEM Manufacturer" width={100} allowEditing={false} />
              <Column caption="Description" dataField="Details" width={700} allowEditing={false} />

            </DataGrid>
          </div>

        )}
      </>
    )
  }

  const renderWarningView = (setFieldValue) => (
    <div>
      <div>
        Are you sure you want to unlink from the Stock System?
      </div>
      <Row className="mt-5" style={{ display: "flex", justifyContent: 'space-around' }}>
        <div className="col-md-2 mt-3">
          <button className="btn text-white rounded-pill  text-wrap  fs-6 primary-button  h-45px"
            onClick={() => { setSelectedStockRows([]); setFieldValue('linkToStockSystem', false); setWarningFlag(false); setPreselectedRows([]) }} title={t('Yes')} type="button" style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac" }}>{t('Yes')}
          </button>
        </div>
        <div className="col-md-2 mt-3">
          <button className="btn text-white rounded-pill  text-wrap  fs-6 primary-button  h-45px"
            onClick={() => setWarningFlag(false)} title={t('No')} type="button" style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac" }}>{t('No')}
          </button>
        </div>
      </Row>
    </div>
  )

  const renderSubmitCommentContent = (Action) => {
    return (
      <Formik
        initialValues={{ commentValue: commentValue }}
        onSubmit={(values) => { setSubmitComment(false); Action(); }}
      >
        {({ handleChange, handleBlur }) => (
          <Form>
            <Field
              as="textarea"
              name="description"
              placeholder="Comment"
              className="form-control"
              style={{ minHeight: '100px' }}
              value={commentValue}
              onChange={(e) => {
                setCommentValue(e.target.value);
                handleChange(e); // Important to call Formik's handleChange
              }}
              onBlur={handleBlur} // Ensure Formik's blur handling
            />
            <div className="col py-3">
              <button
                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                title={t('Submit')}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac" }}>
                {t('Submit')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    )
  }

  const fetStockList = async () => {
    try {
      setTrackStockLoadingState(true)
      const response = await getStockListForContract(company);
      if (response && response.errorCode === 0) {
        setStockListingData(response.data)
      } else {
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");
        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }
      setTrackStockLoadingState(false)
    } catch (error) {
      setTrackStockLoadingState(false)
      toastMessage(t("An error occured Please try again later."), "error");
    }
  }

  const handleSelectionChange = (e) => {
    console.log("Akash selectedRowsData:-",e.selectedRowsData)
    const resultantArray = e.selectedRowsData.map((item) => {
      return ({ 
        "Code": item.Code,
        "Contract Qty": item['Contract Qty'] ?? 0,
        "Unit Price": item['Unit Price'] ?? 0.00,
        "OEM ID": item['OEM I'],
        "Details": item.Details       
      })
    })

    setSelectedStockRows(resultantArray);
  };

  const handleRowUpdate = (e) => {
    // Find the index of the row in the original array
    const rowIndex = selectedStockRows.findIndex(
      (row: any) => row.Code === e.key.Code
    );
    
    if (rowIndex !== -1) {
      // Update the row in the original array
      const updatedRows: any = [...selectedStockRows];
      updatedRows[rowIndex] = { ...updatedRows[rowIndex], ...e.data };
  
      // Update state with the modified array
      setSelectedStockRows(updatedRows);
    }
  };

  console.log("Akash stockListingData:-",stockListingData)
  return (
    <>
      {
        loading && <CustomLoaders />
      }
      <div className="px-10">
        <div className="row h-100">
          <div className={`col-md-12`} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <Formik
              enableReinitialize
              initialValues={initialValue}
              onSubmit={(values) => handleSubmit(values)}
              validationSchema={validationSchema} // Attach the validation schema
              validateOnChange={true}
              validateOnBlur={true}>
              {({ touched, errors, values, setFieldValue, isSubmitting, isValid, resetForm, submitForm }) => {
                return (
                  <Form className="w-100 p-4">
                    <div className="accordion mt-n2" id="headerCard">
                      <div className="accordion-item">
                        <div className="px-4">
                          <div
                            ref={headerRef}
                            className="accordion-button bg-white cursor-pointer"
                            data-bs-toggle="collapse"
                            data-bs-target="#headerData"
                            aria-expanded="true"
                            aria-controls="headerData"
                            onClick={(e) => e.stopPropagation()}>
                            <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                              <div className="col-md-6 d-flex">
                                <div className={clsx("", titleCss)} style={{ color: colors.title }}>
                                  {t('Contract')} - {contractId}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="headerData" className="accordion-collapse collapse show" aria-labelledby="headingTwo" aria-expanded="true" data-bs-parent="#headerCard">
                            <div className="accordion-body">
                              <Card className="py-4">
                                <div className="fw-bold fs-3 mb-4" style={{ textDecorationLine: 'underline' }}>Contract Details</div>
                                <Row>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Contract Code')}</label>
                                      <Field
                                        type="text"
                                        name="Contract Code"
                                        placeholder={t('Contract Code')}
                                        value={values.contractCode}
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={isViewOnly}
                                        onChange={(e) => setFieldValue('contractCode', e.target.value)}
                                      />
                                      {errors?.contractCode && touched.contractCode && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.contractCode}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Contract Start Date')}</label>
                                      <Field
                                        type="date"
                                        name="contractStartDate"
                                        placeholder={t('Contract Start Date')}
                                        value={
                                          values.contractStartDate && values.contractStartDate != null && values.contractStartDate.includes('/')
                                            ? format(parse(values.contractStartDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
                                            : values.contractStartDate // in case it's already in the correct format
                                        }
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={isViewOnly}
                                        onChange={(e) => setFieldValue('contractStartDate', e.target.value)}
                                      />
                                      {errors?.contractStartDate && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.contractStartDate}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Contract End Date')}</label>
                                      <Field
                                        type="date"
                                        name="contractEndDate"
                                        placeholder={t('Contract End Date')}
                                        value={
                                          values.contractEndDate && values.contractEndDate != null && values.contractEndDate.includes('/')
                                            ? format(parse(values.contractEndDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
                                            : values.contractEndDate// in case it's already in the correct format
                                        }
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={isViewOnly}
                                        onChange={(e) => setFieldValue('contractEndDate', e.target.value)}
                                      />
                                      {errors?.contractEndDate && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.contractEndDate}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg={6} md={6} sm={12}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Contract Details')}</label>
                                      <textarea
                                        name={t('Contract Details')}
                                        placeholder={t('Contract Details')}
                                        value={values.contractDetails != null ? (values.contractDetails).replace(/\r/g, '\n') : values.contractDetails}
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={isViewOnly}
                                        onChange={(e) => setFieldValue('contractDetails', e.target.value)}
                                        style={{ minHeight: '100px' }}
                                      />
                                      {errors?.contractDetails && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.contractDetails}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg={6} md={6} sm={12}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Additional Details/Comments')}</label>
                                      <textarea
                                        name={t('Additional Details/Comments')}
                                        placeholder={t('Additional Details/Comments')}
                                        value={values.additionalDetails ? (values.additionalDetails).replace(/\r/g, '\n') : values.additionalDetails}
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={isViewOnly}
                                        onChange={(e) => setFieldValue('additionalDetails', e.target.value)}
                                        style={{ minHeight: '100px' }}
                                      />
                                      {errors?.additionalDetails && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
                                          {errors?.additionalDetails}
                                        </div>
                                      )}
                                    </div>
                                  </Col>

                                  <Col sm={3}>
                                    <SelectInput
                                      optionList={currencyOption}
                                      isViewOnly={isViewOnly}
                                      onChangeAction={(selectedOption) => {
                                        const selectedValue = selectedOption ? selectedOption.value : null;
                                        setFieldValue("amountCurrency", selectedValue);
                                      }}
                                      defaultValue={currencyOption.find((option) => option.value === values.amountCurrency) || null}
                                      errorMsg={errors?.amountCurrency || null}
                                      clearAction={() => { setFieldValue("amountCurrency", "") }}
                                      labelText={t('Currency')}
                                      placeholderText={t("Select Currency")} />
                                  </Col>
                                  <Col sm={3}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Contract Amount')}</label>
                                      <Field name="contractAmount">
                                        {({ field, form }) => (
                                          <NumberBox
                                            format="#,##0.00"
                                            value={cleanNumber(values.contractAmount) || 0} // Use `value` instead of `defaultValue`
                                            placeholder={t('Tax Amount')}
                                            stylingMode="outlined"
                                            height="40px"
                                            disabled={isViewOnly}
                                            min={0}
                                            onValueChanged={(e) => { form.setFieldValue(field.name, e.value) }}
                                            style={{
                                              backgroundColor: isViewOnly ? '#e9ecef' : '#fff',
                                              opacity: isViewOnly ? 1 : undefined,
                                              pointerEvents: isViewOnly ? 'none' : undefined
                                            }} />
                                        )}
                                      </Field>
                                      {errors?.contractAmount && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.contractAmount}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={3}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Commited Amount')}</label>
                                      <Field name="commitedAmount">
                                        {({ field, form }) => (
                                          <NumberBox
                                            format="#,##0.00"
                                            value={cleanNumber(values.commitedAmount) || 0} // Use `value` instead of `defaultValue`
                                            placeholder={t('Commited Amount')}
                                            stylingMode="outlined"
                                            height="40px"
                                            disabled={true}
                                            min={0}
                                            onValueChanged={(e) => { form.setFieldValue(field.name, e.value) }}
                                            style={{
                                              backgroundColor: isViewOnly ? '#e9ecef' : '#fff',
                                              opacity: isViewOnly ? 1 : undefined,
                                              pointerEvents: isViewOnly ? 'none' : undefined
                                            }} />
                                        )}
                                      </Field>
                                      {errors?.commitedAmount && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.commitedAmount}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={3}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Remaining Amount')}</label>
                                      <Field name="remainingAmount">
                                        {({ field, form }) => (
                                          <NumberBox
                                            format="#,##0.00"
                                            value={cleanNumber(values.remainingAmount) || 0} // Use `value` instead of `defaultValue`
                                            placeholder={t('Remaining Amount')}
                                            stylingMode="outlined"
                                            height="40px"
                                            disabled={true}
                                            min={0}
                                            onValueChanged={(e) => { form.setFieldValue(field.name, e.value) }}
                                            style={{
                                              backgroundColor: isViewOnly ? '#e9ecef' : '#fff',
                                              opacity: isViewOnly ? 1 : undefined,
                                              pointerEvents: isViewOnly ? 'none' : undefined
                                            }} />
                                        )}
                                      </Field>
                                      {errors?.remainingAmount && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.remainingAmount}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={3}>
                                    <div className="mb-5">
                                      <div
                                        className="mt-6 pt-1 mb-5"
                                        style={{ display: "flex", alignItems: "center" }}
                                      >
                                        <Field
                                          type="checkbox"
                                          name="linkToStockSystem"
                                          id="linkToStockSystem" // Added id for the label
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setFieldValue('linkToStockSystem', e.target.checked); // Ensure proper syntax
                                              clearSearch();
                                              setStockListFlag(true);
                                            } else {
                                              setWarningFlag(true);
                                            }
                                          }}
                                          disabled={isViewOnly}
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            marginRight: "8px",
                                            marginLeft: "0px",
                                          }}
                                        />
                                        <label
                                          htmlFor="linkToStockSystem"
                                          className="fw-bold fs-6 pt-2" // Adjusted padding for proper alignment
                                        >
                                          {t('Link to Stock System')}
                                        </label>
                                      </div>
                                      {errors?.linkToStockSystem && touched?.linkToStockSystem && (
                                        <div
                                          style={{
                                            color: "#f35a7f",
                                            marginTop: "5px",
                                            fontSize: "11px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {errors.linkToStockSystem}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={3}>
                                    <div className="mt-6 pt-1" style={{ display: "flex", alignItems: "center" }}>
                                      <Field
                                        type="checkbox"
                                        name="registeredAuthority"
                                        onChange={(e) => setFieldValue('registeredAuthority', e.target.checked)}
                                        style={{ width: "20px", height: "20px", marginRight: "8px", marginLeft: "0px" }}
                                        disabled={isViewOnly} />
                                      <label htmlFor="registeredAuthority" className="fw-bold fs-6 pt-3">{t('Registered to Any Authority?')}</label>
                                    </div>
                                    {errors?.registeredAuthority && (
                                      <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
                                        {errors?.registeredAuthority}
                                      </div>
                                    )}
                                  </Col>
                                  <Col sm={3}>
                                      <div className="mb-5">
                                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Status')}</label>
                                        <Field
                                          type="text"
                                          name="registrationRef"
                                          placeholder={t('Registration reference')}
                                          value={values.statusDesc}
                                          autoComplete="false"
                                          className={"form-control disable"}
                                          disabled={true}/>
                                      </div>
                                    </Col>
                                  {
                                    contractId !== 0 && (
                                      <Col sm={3}>
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Attach Document')}</label><br />
                                      <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                        disabled={isViewOnly}
                                        onClick={() => setUploadFileVisibility(prev => !prev)}>
                                        {t("Upload File")}
                                      </Button>
                                    </Col>
                                    )
                                  }
                                 
                                </Row>
                                {values && values.registeredAuthority &&
                                  <Row>
                                    <Col sm={4}>
                                      <div className="mb-5">
                                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Registration reference')}</label>
                                        <Field
                                          type="text"
                                          name="registrationRef"
                                          placeholder={t('Registration reference')}
                                          value={values.registrationRef}
                                          autoComplete="false"
                                          className={isViewOnly ? "form-control disable" : "form-control"}
                                          disabled={isViewOnly}
                                          onChange={(e) => setFieldValue('registrationRef', e.target.value)} />
                                        {errors?.registrationRef && (
                                          <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
                                            {errors?.registrationRef}
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                    <Col sm={4}>
                                      <div className="mb-5">
                                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Authority Registration Date')}</label>
                                        <Field
                                          type="date"
                                          name="authorityRegistrationDate"
                                          placeholder={t('Authority Registration Date')}
                                          value={
                                            values.authorityRegistrationDate && values.authorityRegistrationDate != null && values.authorityRegistrationDate.includes('/')
                                              ? format(parse(values.authorityRegistrationDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
                                              : values.authorityRegistrationDate// in case it's already in the correct format
                                          }
                                          className={isViewOnly ? "form-control disable" : "form-control"}
                                          disabled={isViewOnly}
                                          onChange={(e) => setFieldValue('authorityRegistrationDate', e.target.value)}
                                        />
                                        {errors?.authorityRegistrationDate && (
                                          <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.authorityRegistrationDate}</div>
                                        )}
                                      </div>
                                    </Col>
                                    <Col sm={4}>
                                      <div className="mb-5">
                                        <label htmlFor="authorityName" className="fw-bold fs-6 mb-2">{t('Authority Name')}</label>
                                        <Field
                                          type="text"
                                          name="authorityName"
                                          placeholder={t('Authority Name')}
                                          value={values.authorityName}
                                          autoComplete="false"
                                          className={isViewOnly ? "form-control disable" : "form-control"}
                                          disabled={isViewOnly}
                                          onChange={(e) => setFieldValue('authorityName', e.target.value)} />
                                        {errors?.authorityName && (
                                          <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
                                            {errors?.authorityName}
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                }
                                <div className="fw-bold fs-3 mt-8 mb-5" style={{ textDecorationLine: 'underline' }}>Vendor Details</div>
                                <Row>
                                  <Col sm={4}>
                                    <SelectInput
                                      optionList={vendorOption}
                                      isViewOnly={isViewOnly}
                                      onChangeAction={(selectedOption) => {
                                        const selectedValue = selectedOption ? selectedOption.value : null;
                                        setFieldValue("vendorCode", selectedValue);
                                        const vendorName = vendorCodeNameMapper.filter((item) => item.code === selectedValue && (item));
                                        setFieldValue("vendorName", vendorName[0]['name']);
                                        if (selectedOption.invoiceCurrency !== '') {
                                          setFieldValue("invoiceCurrency", selectedOption.currency)
                                        }
                                        if (selectedOption.email !== '') {
                                          setFieldValue("vendorEmail", selectedOption.email)
                                        }
                                      }}
                                      defaultValue={vendorOption.find((option) => option.value === values.vendorCode) || null}
                                      errorMsg={errors?.vendorCode || null}
                                      clearAction={() => { setFieldValue("vendorCode", ""); setFieldValue("vendorName", "") }}
                                      labelText={t('Vendor Code')}
                                      placeholderText={t("Select Vendor Code")} />
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Vendor Name')}</label>
                                      <Field
                                        type="Name"
                                        name="vendorName"
                                        placeholder={t('Vendor Name')}
                                        value={values.vendorName}
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={isViewOnly}
                                      />
                                      {errors?.vendorName && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.vendorName}</div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Vendor Email')}</label>
                                      <Field
                                        type="Email"
                                        name="vendorEmail"
                                        placeholder={t('Vendor Email')}
                                        value={values.vendorEmail}
                                        className={isViewOnly ? "form-control disable" : "form-control"}
                                        disabled={isViewOnly}
                                      />
                                      {errors?.vendorEmail && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.vendorEmail}</div>
                                      )}
                                    </div>
                                  </Col>
                                </Row>

                                <div className="fw-bold fs-3 mt-8 mb-5" style={{ textDecorationLine: 'underline' }}>Authorization Details</div>
                                <Row>
                                  <Col sm={4}>
                                    <SelectInput
                                      optionList={authgroupOption}
                                      isViewOnly={isViewOnly || readAuthGroupFlag}
                                      onChangeAction={(selectedOption) => {
                                        const selectedValue = selectedOption ? selectedOption.value : null;
                                        setFieldValue("authGroup", selectedValue);
                                        setAuthGroupCode(selectedValue)
                                        fetchAuthorizedByOption(selectedValue)
                                        setFieldValue("approvalType", selectedOption.authType)
                                      }}
                                      defaultValue={authgroupOption.find((option) => option.value === values.authGroup) || null}
                                      errorMsg={errors?.authGroup || null}
                                      clearAction={() => { setFieldValue("authGroup", ""); setFieldValue("authorizeBy", ""); setFieldValue("approvalType", "") }}
                                      labelText={t('Auth Group')}
                                      placeholderText={t("Select Auth Group")} />
                                  </Col>
                                  <Col sm={4}>
                                    <SelectInput
                                      optionList={authorizedByOption.some(option => option.value === values.authorizeBy)
                                        ? authorizedByOption
                                        : [...authorizedByOption, { value: values.authorizeBy, label: values.authorizeBy }]}
                                      isViewOnly={isViewOnly || authorizedByOption.length === 0 || readAuthByFlag}
                                      onChangeAction={(selectedOption) => {
                                        const selectedValue = selectedOption ? selectedOption.value : null;
                                        setFieldValue("authorizeBy", selectedValue);
                                      }}
                                      defaultValue={authorizedByOption.find((option) => option.value === values.authorizeBy) || { value: values.authorizeBy, label: values.authorizeBy }}
                                      errorMsg={errors?.authorizeBy || null}
                                      clearAction={() => { setFieldValue("authorizeBy", "") }}
                                      labelText={t('Authorized By')}
                                      placeholderText={t("Select Authorized By")} />
                                  </Col>
                                  <Col sm={4}>
                                    <SelectInput
                                      optionList={approvalTypeOption}
                                      isViewOnly={true}
                                      onChangeAction={(selectedOption) => ''}
                                      defaultValue={approvalTypeOption.find((option) => option.value === values.approvalType) || null}
                                      errorMsg={errors?.approvalType || null}
                                      clearAction={() => ''}
                                      labelText={t('Approval Type')}
                                      placeholderText={t("Select Approval Type")} />
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Requested By')}</label>
                                      <Field
                                        type="text"
                                        name="requestedBy"
                                        placeholder={t('Requested By')}
                                        value={values.requestedBy ? values.requestedBy : userName}
                                        autoComplete="false"
                                        className={"form-control disable"}
                                        disabled={true}
                                        onChange={(e) => setFieldValue('requestedBy', e.target.value)} />
                                      {errors?.requestedBy && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
                                          {errors?.requestedBy}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Authorization History')}</label>
                                      <textarea
                                        name={t('Authorization History')}
                                        placeholder={t('Authorization History')}
                                        value={values.authHistory != null ? (values.authHistory).replace(/\r/g, '\n') : values.authHistory}
                                        className="form-control"
                                        disabled={true}
                                        style={{ minHeight: '155px' }}
                                      />
                                      {errors?.authHistory && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
                                          {errors?.authHistory}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="mb-5">
                                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Comments')}</label>
                                      <textarea
                                        name={t('Comments')}
                                        placeholder={t('Comments')}
                                        value={values.notes != null ? (values.notes).replace(/\r/g, '\n') : values.notes}
                                        className="form-control"
                                        disabled={true}
                                        style={{ minHeight: '155px' }}
                                      />
                                      {errors?.notes && (
                                        <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
                                          {errors?.notes}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      values.linkToStockSystem &&
                      <div className="accordion mt-5" id="stockListing">
                        <div className="accordion-item">
                          <div className="px-4">
                            <div
                              ref={headerRef}
                              className="accordion-button bg-white cursor-pointer"
                              data-bs-toggle="collapse"
                              data-bs-target="#stockListingData"
                              aria-expanded="true"
                              aria-controls="stockListingData"
                              onClick={(e) => e.stopPropagation()}>
                              <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                                <div className="col-md-6 d-flex">
                                  <div className={clsx("", titleCss)} style={{ color: colors.title }}>
                                    {t('Stock Listing')} - {contractId}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id="stockListingData" className="accordion-collapse collapse" aria-labelledby="headingTwo" aria-expanded="true" data-bs-parent="#stockListing">
                              <div className="accordion-body">
                                <Card className="p-4">
                                  {trackStockLoadingState ? (<CustomLoadingCellRender />) : (
                                    <div style={{ flex: 1, width: "100%" }}>
                                      <DataGrid
                                        dataSource={selectedStockRows}
                                        showBorders={true}
                                        keyExpr="Details"
                                        allowColumnResizing={true}
                                        columnResizingMode="widget"
                                        allowColumnReordering={true}
                                        width="100%"
                                        onRowRemoved={(e) => handleRowDeleted(e)}
                                        onRowUpdated={(e) => handleRowUpdate(e)}
                                        onSelectionChanged={handleSelectionChange}>

                                        <Toolbar>

                                          <Item location="before">
                                            {
                                              !isViewOnly && 
                                              <div style={{ marginLeft: "auto" }}>
                                              <button
                                                className="btn btn-primary"
                                                onClick={(e) => { e.preventDefault(); setStockListFlag(true); clearSearch(); }}>
                                                Add Item
                                              </button>
                                            </div>
                                            }
                                           
                                          </Item>
                                        </Toolbar>
                                        <SearchPanel visible={true} width={240} placeholder="Search..." />
                                        {
                                          !isViewOnly && (
                                            <Column type="buttons" width={80} buttons={[{
                                              name: 'delete', icon: 'trash', onClick: (e: any) => {
                                                handleRowDeleted({ data: e.row.data });
                                                e.component.deleteRow(e.row.rowIndex);
                                              },
                                            },]} caption="Actions" />
                                          )
                                        }
                                       
                                        <Column caption="Code" dataField="Code" width={100} />
                                        <Column caption="Contract Qty" dataField="Contract Qty" width={100} allowEditing={true} dataType='number' />
                                        <Column caption='Unit Price' dataField='Unit Price' width={100} allowEditing={true} dataType='number' format="#,##0.00" />
                                        {/* <Column caption="OEM ID" dataField="OEM ID" width={100} allowEditing={false} /> */}
                                        <Column caption="Description" dataField="Details" width={700} allowEditing={false} />
                                        <Editing mode="cell" allowUpdating={true} allowDeleting={true} confirmDelete={false} />
                                      </DataGrid>
                                    </div>

                                  )}
                                </Card>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }


                    {
                      contractId != 0 &&
                      <div className="accordion mt-5" id="attachedDocument">
                        <div className="accordion-item">
                          <div className="px-4">
                            <div
                              ref={headerRef}
                              className="accordion-button bg-white cursor-pointer"
                              data-bs-toggle="collapse"
                              data-bs-target="#attachedDocumentData"
                              aria-expanded="true"
                              aria-controls="attachedDocumentData"
                              onClick={(e) => e.stopPropagation()}>
                              <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                                <div className="col-md-6 d-flex">
                                  <div className={clsx("", titleCss)} style={{ color: colors.title }}>
                                    {t('Document Attached')} - {contractId}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id="attachedDocumentData" className="accordion-collapse collapse" aria-labelledby="headingThree" aria-expanded="true" data-bs-parent="#attachedDocument">
                              <div className="accordion-body">
                                <Card className="p-4">
                                  <List
                                    dataSource={attachmentList}
                                    height="100%"
                                    itemRender={attachmentView} />

                                  {
                                    selectedAttachmentArray && selectedAttachmentArray.length > 0 &&
                                    <Button variant="outlined" className="col-2" startIcon={<DeleteIcon />} onClick={deleteSelectedAttachment}>
                                      Delete
                                    </Button>
                                  }
                                </Card>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }


                    <div className="accordion mt-5" id="poHistory">
                      <div className="accordion-item">
                        <div className="px-4">
                          <div
                            ref={headerRef}
                            className="accordion-button bg-white cursor-pointer"
                            data-bs-toggle="collapse"
                            data-bs-target="#poHistoryData"
                            aria-expanded="true"
                            aria-controls="poHistoryData"
                            onClick={(e) => e.stopPropagation()}>
                            <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                              <div className="col-md-6 d-flex">
                                <div className={clsx("", titleCss)} style={{ color: colors.title }}>
                                  {t('PO History')}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="poHistoryData" className="accordion-collapse collapse" aria-labelledby="headingFour" aria-expanded="true" data-bs-parent="#poHistory">
                            <div className="accordion-body">
                              <Card className=" px-10">
                                {trackLoadingState ? (<CustomLoadingCellRender />) : (
                                  <DevDynamicGridTable
                                    type={option}
                                    tabTitle={'PO List'}
                                    tabSqlData={poRowData}
                                    tabStorageData={poColumnDefs}
                                    tabStorage={poTabStorage}
                                    tabSqlName={poTabSqlName}
                                    tableConfiguration={poTableConfiguration}
                                    tabId={'0'}
                                    fetchOthersTabData={() => ''}
                                    customizeGridEnabled={true}
                                    onSelectionChanged={(e) => setPoSeletedRow(e.selectedRowKeys)}
                                    exportFlag={false}
                                  />
                                )}
                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      (!isViewOnly || previousPage !== 'create') &&
                      <Row className="mt-5 mb-5" style={{ display: "flex", justifyContent: "center" }}>
                        <div className="col-md-2 mt-3">
                          <button
                            className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                            onClick={() => {
                              if (previousPage === 'create') {
                                resetForm();  // Reset the form
                              } else {
                                navigate('/approveContract');  // Navigate to the approve bank details page
                              }
                            }} title={t(previousPage === 'create' ? 'Reset' : 'Cancel')}
                            type="button"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#0a7eac",
                            }}
                          >
                            {t(previousPage === 'create' ? 'Reset' : 'Cancel')}
                          </button>
                        </div>
                        {
                          (previousPage === 'create' || previousPage === 'approve') &&
                          <div className="col-md-2 mt-3">
                            <button
                              className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                              type="button"
                              onClick={(e) => { e.preventDefault(); setFieldValue('action', 'save'); submitForm() }} // Call handleSubmit with 'save' action
                              title={t('Save')}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#0a7eac",
                              }}
                            >
                              {t('Save')}
                            </button>
                          </div>
                        }
                        {
                          previousPage === 'approve' && (
                            <>
                            <div className="col-md-2 mt-3">
                            <button
                              className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                              type="button"
                              onClick={(e) => { e.preventDefault(); setFieldValue('action', 'save'); setSubmitComment(true); setActionType('approve')}} // Call handleSubmit with 'save' action
                              title={t('Approve')}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#0a7eac",
                              }}
                            >
                              {t('Approve')}
                            </button>
                          </div>
                          <div className="col-md-2 mt-3">
                            <button
                              className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                              type="button"
                              onClick={(e) => { e.preventDefault(); setFieldValue('action', 'save'); setSubmitComment(true); setActionType('reject') }} // Call handleSubmit with 'save' action
                              title={t('Reject')}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#0a7eac",
                              }}
                            >
                              {t('Reject')}
                            </button>
                          </div>
                            </>
                          )
                        }

                        {/* {
                                                  previousPage === 'approve' &&
                                                  <>
                                                    <div className="col-md-2 mt-3">
                                                      <button
                                                        className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                                                        type="button"
                                                        onClick={() => openComment('approve')}
                                                        title={t('Approve')}
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent: "center",
                                                          backgroundColor: "#0a7eac",
                                                        }}
                                                      >
                                                        {t('Approve')}
                                                      </button>
                                                    </div>
                                                    <div className="col-md-2 mt-3">
                                                      <button
                                                        className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                                                        type="button"
                                                        onClick={() => openComment('reject')}
                                                        title={t('Reject')}
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent: "center",
                                                          backgroundColor: "#0a7eac",
                                                        }}
                                                      >
                                                        {t('Reject')}
                                                      </button>
                                                    </div>
                                                  </>
                                                } */}
                        {
                          previousPage === 'create' &&
                          <div className="col-md-2 mt-3">
                            <button
                              className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                              type="button"
                              onClick={(e) => { e.preventDefault(); setFieldValue('action', 'submit'); setSubmitComment(true); }} // Call handleSubmit with 'submit' action
                              title={t('Submit')}
                              // type="submit"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#0a7eac",
                              }}
                            >
                              {t('Submit')}
                            </button>
                          </div>
                        }
                      </Row>
                    }
                    {
                      submitComment &&
                      <Popup
                        visible={submitComment}
                        hideOnOutsideClick={true}
                        onHiding={(e) => setSubmitComment(false)}
                        showCloseButton={true}
                        resizeEnabled={true}
                        title={t('Comment')}
                        width={335}
                        height={280}
                        contentRender={() => renderSubmitCommentContent(() => submitForm())} />
                    }
                    <Popup
                      visible={warningFlag}
                      hideOnOutsideClick={false}
                      onHiding={(e) => setWarningFlag(false)}
                      showCloseButton={true}
                      resizeEnabled={true}
                      title={'Warning'}
                      width={335}
                      height={240}
                      contentRender={() => renderWarningView(setFieldValue)} />
                     <Popup
                      visible={stockListFlag}
                      hideOnOutsideClick={true}
                      onHiding={() => {
                        setStockListFlag(false);
                        clearSearch();
                        if (selectedStockRows && selectedStockRows.length === 0) {
                          setFieldValue('linkToStockSystem', false);
                        }
                      }}
                      showCloseButton={true}
                      resizeEnabled={true}
                      title="Stock List"
                      contentRender={renderStockView}
                    />
                  </Form>
                )
              }}

            </Formik>
            <UploadFile
              visibilityFlag={uploadFileVisibility}
              title={t("Upload Attachments")}
              onHideFunction={() => setUploadFileVisibility(false)}
              multipleFlag={true}
              height={400}
              fileExtensions={fileExtensions}
              onSelectedFilesChanged={onSelectedFilesChanged}
              onUploadAction={uploadFileAction} />
            

          </div>
        </div>
      </div >
    </>
  )
}
