import { Field, Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import CustomLoaders from '../user/CustomLoaders'
import { toastMessage } from '../../modules/auth/functions/toastMessage';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { approvebankdetail, deleteAttachment, deleteBankAttachment, getbankdauthorizers, getBankDetailsById, getcountryList, getDoc, rejectbankdetail, saveBankAttachment, savebanktransaction } from '../../modules/apps/user-management/users-list/core/_requests';
import { Container, Row, Col, Card } from "react-bootstrap";
import Select from "react-select";
import ReactSelectStyle from "../../utils/ReactSelectStyle";
import { colors, titleCss } from "../../../constants/colors";
import clsx from "clsx";
import { Popup } from 'devextreme-react/popup';
import List from 'devextreme-react/list';
import CheckBox from 'devextreme-react/cjs/check-box';
import { IconButton, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadFile from '../../modules/custom components/uploadFile';
import { useAuth } from '../../modules/auth';
import InputMask from "react-input-mask";

interface LocationState {
  bankDetailId?: any;
  isViewOnly?: any;
  previousPage?: any;
}

export default function EditBankDetails() {

  const { t } = useTranslation();
  const location = useLocation();
  const { bankDetailId } = (location.state as LocationState) || {};
  const { isViewOnly } = location.state as LocationState || false;
  const { previousPage } = location.state as LocationState || 'create';
  const company = useSelector((state: any) => state?.userConfig?.company);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [userEmail, userId, userName]: any = currentUser?.sub?.split("#");

  const headerRef: any = useRef(null);

  
  const [loading, setLoading] = useState(false)
  const [vendorCode, setVendorCode] = useState('')
  const [vendorName, setVendorName] = useState('')
  const [countryOption, setCountryOptioon] = useState([])
  const [readAuthByFlag, setReadAuthByFlag] = useState(false)
  const [commentVisibilityFlag, setCommentVisibilityFlag] = useState(false)
  const [actionType, setActionType] = useState('')
  const [approvedByOption, setApproveByOption] = useState([])
  const [commentValue, setCommentValue] = useState('');
  const [attachmentList, setAttachmentList] = useState([])
  const [selectedAttachmentArray, setSelectedAttachmentArray] = useState<any>([])
  const [uploadFileVisibility, setUploadFileVisibility] = useState<boolean>(false)
  const [selectedFiles, setSelectedFiles] = useState<any>([])
  const [initialValue, setInitialValue] = useState({
    bankName: '',
    branch: '',
    bsbNo: '',
    acctNo: '',
    acctName: '',
    country: '',
    ibanCode: '',
    swiftCode: '',
    approvedBy: '',
    status: '',
    oldBankD: '',
    ridCode: '',
    notes: '',
    originator: ''
  });
  const [submitComment, setSubmitComment] = useState(false)

  const fileExtensions = ['.jpg', '.jpeg', '.png', '.pdf'].join(',');


  useEffect(() => {
    fetchBankDetailsById()
    fetchCountryList()
    fetchAuthorizersList()
  }, [])

  const fetchBankDetailsById = async () => {
    try{
      setLoading(true);

      const response = await getBankDetailsById(company, bankDetailId);
      if(response && response.errorCode === 0 && response.data) {
        setInitialValue({
          bankName: response.data.bankName || '',
          branch: response.data.branch || '',
          bsbNo: response.data.bsbNo || '',
          acctNo: response.data.acctNo || '',
          acctName: response.data.acctName || '',
          country: response.data.country || '',
          ibanCode: response.data.ibanCode || '',
          swiftCode: response.data.swiftCode || '',
          approvedBy: response.data.approvedBy || '',
          status: response.data.status || '',
          oldBankD: response.data.oldBankD || '',
          ridCode: response.data.ridCode || '',
          notes: response.data.notes || '',
          originator: response.data.originator || '',
        });
        setVendorCode(response.data.vendorCode);
        setVendorName(response.data.vendorName);
        if(response.data.attachments) {
          setAttachmentList(response.data.attachments)
        }
      } else {
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");
        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later handleSubmit."), "error");
    }
  }

  const fetchCountryList = async () => {
      try{
        setLoading(true);
        const response = await getcountryList(company);
        if(response && response.errorCode === 0 && response.data) {
          const transformedUsers = response.data.map(user => ({
            value: user.code,
            label: `${user.code} - ${user.name}`
          }));
          setCountryOptioon(transformedUsers);
        } else {
          if (response.errorMsg) {
            toastMessage(t(response.errorMsg), "error");
          } else {
            toastMessage(t("An error occured Please try again later."), "error");
          }
        }
        setLoading(false);
      } catch (error) {
        toastMessage(t("An error occurred. Please try again later handleSubmit."), "error");
      }
  }

  const fetchAuthorizersList = async () => {
    try{
      setLoading(true);
      const response = await getbankdauthorizers(company);
      if(response && response.errorCode === 0 && response.data) {
        const transformedUsers = response.data.map(user => ({
          value: user.userId,
          label: `${user.userId} - ${user.userName}`
        }));
        setApproveByOption(transformedUsers);
      } else {
        if (response.errorMsg) {
          toastMessage(t(response.errorMsg), "error");
        } else {
          toastMessage(t("An error occured Please try again later."), "error");
        }
      }
      setLoading(false);
    } catch (error) {
      toastMessage(t("An error occurred. Please try again later handleSubmit."), "error");
    }
}

    const handleSubmit = async (values) => {
      try {
        const formData = new FormData();
        const payload = { ...values, seqNo: bankDetailId, vendorCode: vendorCode }
        const response = await savebanktransaction(company, payload);
        if (response && response.errorCode === 0) {
          toastMessage(response.successMsg, "success");
              if (previousPage === 'create') {
                if(values.action === 'save') {
                  fetchBankDetailsById()
                  fetchCountryList()
                  fetchAuthorizersList()
                } else {
                  navigate('/bankDetailList');
                } 
              } else {
                navigate('/bankApprovalList')
              }
       
        } else {
          if (response.errorMsg) {
            toastMessage(t(response.errorMsg), "error");
          } else {
            toastMessage(t("An error occured Please try again later."), "error");
          }
        }
      } catch (error) {
        toastMessage(t("An error occurred. Please try again later handleSubmit."), "error");
      }
    };

    const openComment = (value) => {
      setCommentVisibilityFlag(true);
      setActionType(value);
    }

    const commentAdded = () => {
      setCommentVisibilityFlag(false);
      onSubmitAction();
    }

    const onSubmitAction = async () => {
      try {
        setLoading(true);
  
        const payload = {
          'notes': commentValue,
          'seqNos': [bankDetailId]
        }

        let response: any = null
        if(actionType === 'approve') {
          response = await approvebankdetail(company, payload);
        } else {
          response = await rejectbankdetail(company, payload);
        }
        if(response && response.errorCode === 0) {
          toastMessage(t(response.successMsg),"success");
          if (previousPage === 'create') {
            navigate('/bankDetailList')
          } else {
            navigate('/bankApprovalList')
          }
        } else {
          if(response.errorMsg) {
            toastMessage(t(response.errorMsg),"error");
  
          } else {
            toastMessage(t("An error occured Please try again later."),"error");
          }
        }
      } catch (error) {
        toastMessage(t("An error occured Please try again later."),"error");
      }
      finally{
        setLoading(false);
      }
    }

    const renderContent = () => {
      return (
        <Formik
          initialValues={{ commentValue: commentValue }}
          onSubmit={(values) => commentAdded()}
        >
          {({ handleChange, handleBlur }) => (
            <Form>
              <Field
                as="textarea"
                name="description"
                placeholder={t("Comment")}
                className="form-control"
                style={{ minHeight: '100px' }}
                value={commentValue}
                onChange={(e) => {
                  setCommentValue(e.target.value);
                  handleChange(e); // Important to call Formik's handleChange
                }}
                onBlur={handleBlur} // Ensure Formik's blur handling
              />
              <div className="col py-3">
                <button
                  className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                  title={t('Submit')}
                  style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac" }}>
                  {t('Submit')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )
    }

    const attachmentView = (item) => (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div><CheckBox
          defaultValue={false}
          onValueChanged={(e) => attachmentCheckBoxAction(e.value, item.attachmentId)}
          text={item.fileName} /></div>
        <div>
          <IconButton aria-label="view" size="large" onClick={() => viewAttachment(item)}>
            <VisibilityIcon fontSize="inherit" />
          </IconButton>
          {
            !isViewOnly &&
            <IconButton aria-label="delete" size="large" onClick={() => removeAttachment(item.attachmentId)}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          }
        </div>
      </div>
    )

    const attachmentCheckBoxAction = (value, id) => {
      if (value) {
        setSelectedAttachmentArray(prevArray => [...prevArray, id]);
      } else {
        setSelectedAttachmentArray(selectedAttachmentArray.filter((element) => element !== id && (element)));
      }
    }

    const viewAttachment = async (obj) => {
      try {
        setLoading(true);
        const response = await getDoc(obj.path);
        const contentType = response.headers['content-type'];
  
        if (response.status === 200) {
          // Ensure response.data is a buffer or array buffer
          const data = new Blob([response.data], { type: contentType });
          const url = URL.createObjectURL(data);
          window.open(url, '_blank');
        } else {
          toastMessage(t("An error occurred. Please try again later."), "error");
        }
      } catch (error) {
        toastMessage(t("An error occurred. Please try again later."), "error");
      } finally {
        setLoading(false);
      }
    }

    const removeAttachment = async (id) => {
      try {
        setLoading(true);
        const response = await deleteBankAttachment(bankDetailId, company, [id]);
        if (response && response.errorCode === 0 && response.data) {
          setAttachmentList(response.data)
        } else {
          if (response.errorMsg) {
            toastMessage(t(response.errorMsg), "error");
          } else {
            toastMessage(t("An error occured Please try again later."), "error");
          }
        }
        setLoading(false);
      } catch (error) {
        toastMessage(t("An error occurred. Please try again later."), "error");
        setLoading(false);
      }
    }

    const onSelectedFilesChanged = (e) => {
      setSelectedFiles(e.value);
    }

    const uploadFileAction = async () => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("companyCode", company);
        formData.append("seqNo", bankDetailId);
  
        if (selectedFiles && selectedFiles.length > 0) {
          selectedFiles.forEach(file => {
            formData.append("attachments", file);
          });
        }
        const response = await saveBankAttachment(formData);
        if (response && response.errorCode === 0 && response.data) {
          setAttachmentList(response.data);
          setSelectedFiles([]);
  
        } else {
          toastMessage(response.errorMsg, "error")
        }
        setUploadFileVisibility(false);
        setLoading(false);
      } catch (error) {
        toastMessage(t("An error occurred. Please try again later."), "error");
        setLoading(false);
      }
    };

    const deleteSelectedAttachment = async () => {
      try {
        setLoading(true);
        const response = await deleteBankAttachment(bankDetailId, company, selectedAttachmentArray);
        if (response && response.errorCode === 0 && response.data) {
          setAttachmentList(response.data)
          setSelectedAttachmentArray([])
        } else {
          if (response.errorMsg) {
            toastMessage(t(response.errorMsg), "error");
          } else {
            toastMessage(t("An error occured Please try again later."), "error");
          }
        }
        setLoading(false);
      } catch (error) {
        toastMessage(t("An error occurred. Please try again later."), "error");
        setLoading(false);
      }
    }

    const renderSubmitCommentContent = (Action) => {
      return (
        <Formik
          initialValues={{ commentValue: commentValue }}
          onSubmit={(values) => { setSubmitComment(false); Action(); }}
        >
          {({ handleChange, handleBlur }) => (
            <Form>
              <Field
                as="textarea"
                name="description"
                placeholder={t("Comment")}
                className="form-control"
                style={{ minHeight: '100px' }}
                value={commentValue}
                onChange={(e) => {
                  setCommentValue(e.target.value);
                  handleChange(e); // Important to call Formik's handleChange
                }}
                onBlur={handleBlur} // Ensure Formik's blur handling
              />
              <div className="col py-3">
                <button
                  className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                  title={t('Submit')}
                  style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0a7eac" }}>
                  {t('Submit')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )
    }

  return (
    <>
     {
        loading && <CustomLoaders />
      }
      <div className="px-10">
      <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
              <div className="col d-flex">
                <div className={clsx("", titleCss)} style={{ color: colors.title }}>
                  {vendorName} -  {vendorCode}
                </div>
              </div>
            </div>
        <div className="row h-100">
          <div className={`col-md-12} d-flex align-items-center`} style={{ paddingTop: '10px', paddingBottom: '10px'}}>
            <Formik
              enableReinitialize
              initialValues={initialValue}
              onSubmit={(values) => handleSubmit(values)}
              validateOnChange={true}
              validateOnBlur={true}>
              {({ touched, errors, values, setFieldValue, isSubmitting, isValid, resetForm, submitForm }) => {
                console.log("test:-",(values.oldBankD).replace(/\r/g,'\n'))
                return (
                  <Card className="py-4">
                    <Row>
                      <Col sm={3}>
                        <div className="mb-5">
                          <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Bank Name')}</label>
                          <Field
                            type="text"
                            name="bank Name"
                            placeholder={t('Bank Name')}
                            value={values.bankName}
                            className={isViewOnly ? "form-control disable" : "form-control"}
                            disabled={isViewOnly}
                            onChange={(e) => setFieldValue('bankName', e.target.value)}
                          />
                          {errors?.bankName && (
                            <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.bankName}</div>
                          )}
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="mb-5">
                          <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Branch')}</label>
                          <Field
                            type="text"
                            name="Branch"
                            placeholder={t('Branch')}
                            value={values.branch}
                            className={isViewOnly ? "form-control disable" : "form-control"}
                            disabled={isViewOnly}
                            onChange={(e) => setFieldValue('branch', e.target.value)}
                          />
                          {errors?.branch && (
                            <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.branch}</div>
                          )}
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="mb-5">
                          <label htmlFor="bsbNo" className="fw-bold fs-6 mb-2">{t('Branch No.')}</label>
                          <Field name="bsbNo">
                            {({ field }) => (
                              <InputMask
                                {...field}
                                mask="999-999"
                                maskChar=" "  // Allows spaces in place of numbers
                                placeholder={t('Branch No.')}
                                className={isViewOnly ? "form-control disable" : "form-control"}
                                disabled={isViewOnly}
                                onChange={(e) => setFieldValue('bsbNo', e.target.value)}
                              />
                            )}
                          </Field>
                          {errors?.bsbNo && (
                            <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
                              {errors?.bsbNo}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="mb-5">
                          <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Account No.')}</label>
                          <Field
                            type="text"
                            name="Acct No."
                            placeholder={t('Account No.')}
                            value={values.acctNo}
                            className={isViewOnly ? "form-control disable" : "form-control"}
                            disabled={isViewOnly}
                            onChange={(e) => setFieldValue('acctNo', e.target.value)}
                          />
                          {errors?.acctNo && (
                            <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.acctNo}</div>
                          )}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-5">
                          <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Account Name')}</label>
                          <Field
                            type="text"
                            name="Acct Name"
                            placeholder={t('Account Name')}
                            value={values.acctName}
                            className={isViewOnly ? "form-control disable" : "form-control"}
                            disabled={isViewOnly}
                            onChange={(e) => setFieldValue('acctName', e.target.value)}
                          />
                          {errors?.acctName && (
                            <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.acctName}</div>
                          )}
                        </div>
                      </Col>
                      
                      <Col sm={3}>
                        <div className="mb-5">
                          <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('IBAN Code')}</label>
                          <Field
                            type="text"
                            name="Iban Code"
                            placeholder={t('IBAN Code')}
                            value={values.ibanCode}
                            className={isViewOnly ? "form-control disable" : "form-control"}
                            disabled={isViewOnly}
                            onChange={(e) => setFieldValue('ibanCode', e.target.value)}
                          />
                          {errors?.ibanCode && (
                            <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.ibanCode}</div>
                          )}
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="mb-5">
                          <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Requested By')}</label>
                          <Field
                            type="text"
                            name="Iban Code"
                            placeholder={t('Requested By')}
                            value={values.originator}
                            className={"form-control disable"}
                            disabled={true}
                            onChange={(e) => setFieldValue('originator', e.target.value)}
                          />
                          {errors?.originator && (
                            <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.originator}</div>
                          )}
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="mb-5">
                          <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Country')}</label>
                          <div style={{ zIndex: 999999999 }}>
                            <Select
                              options={countryOption}
                              isDisabled={isViewOnly || countryOption.length === 0 || readAuthByFlag}
                              onChange={(selectedOption: any) => {
                                const selectedValue = selectedOption ? selectedOption.value : null;
                                setFieldValue("country", selectedValue);
                              }}
                              classNamePrefix="custom-select"
                              value={countryOption.find((option: any) => option.value === values.country) || null}
                              styles={ReactSelectStyle}
                              placeholder={t("Select Country")}
                            />
                          </div>
                          {errors?.country && (
                            <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.country}</div>
                          )}
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="mb-5">
                          <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Swift Code')}</label>
                          <Field
                            type="text"
                            name="Swift Code"
                            placeholder={t('Swift Code')}
                            value={values.swiftCode}
                            className={isViewOnly ? "form-control disable" : "form-control"}
                            disabled={isViewOnly}
                            onChange={(e) => setFieldValue('swiftCode', e.target.value)}
                          />
                          {errors?.swiftCode && (
                            <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.swiftCode}</div>
                          )}
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="mb-5">
                          <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('RIB Code')}</label>
                          <Field
                            type="text"
                            name="Rid Code"
                            placeholder={t('RIB Code')}
                            value={values.ridCode}
                            className={isViewOnly ? "form-control disable" : "form-control"}
                            disabled={isViewOnly}
                            onChange={(e) => setFieldValue('ridCode', e.target.value)}
                          />
                          {errors?.swiftCode && (
                            <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.ridCode}</div>
                          )}
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="mb-5">
                          <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Approved By')}</label>
                          <div style={{ zIndex: 999999999 }}>
                            <Select
                              options={approvedByOption}
                              isDisabled={isViewOnly || approvedByOption.length === 0 || readAuthByFlag}
                              onChange={(selectedOption: any) => {
                                const selectedValue = selectedOption ? selectedOption.value : null;
                                setFieldValue("approvedBy", selectedValue);
                              }}
                              classNamePrefix="custom-select"
                              value={approvedByOption.find((option: any) => option.value === values.approvedBy) || null}
                              styles={ReactSelectStyle}
                              placeholder={t("Select Approved By")}
                            />
                          </div>
                          {errors?.approvedBy && (
                            <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.approvedBy}</div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                    <Col lg={3} md={6} sm={12}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{values.status === 'SX' ? t('Old Bank Details') : t('Current Bank Details')}</label>
                        <textarea
                          name={values.status === 'SX' ? t('Old Bank Details') : t('Current Bank Details')}
                          placeholder={values.status === 'SX' ? t('Old Bank Details') : t('Current Bank Details')}
                          value={(values.oldBankD).replace(/\r/g, '\n')}
                          className="form-control"
                          disabled={true}
                          onChange={(e) => setFieldValue('oldBankD', e.target.value)}
                          style={{ minHeight: '155px' }}
                        />
                        {errors?.oldBankD && (
                          <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
                            {errors?.oldBankD}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <div className="mb-5">
                        <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Notes')}</label>
                        <textarea
                          name={t('Notes')}
                          placeholder={t('Notes')}
                          value={(values.notes)}
                          className="form-control"
                          disabled={true}
                          onChange={(e) => setFieldValue('notes', e.target.value)}
                          style={{ minHeight: '155px' }}
                        />
                        {errors?.notes && (
                          <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>
                            {errors?.notes}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col sm={3}>
                        <div className="mb-5">
                          <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Status')}</label>
                          <Field
                            type="text"
                            name="Status"
                            placeholder={t('Status')}
                            value={values.status}
                            className={"form-control disable"}
                            disabled={true}
                            onChange={(e) => setFieldValue('status', e.target.value)}
                          />
                          {errors?.status && (
                            <div style={{ color: "#f35a7f", marginTop: "5px", fontSize: "11px", fontWeight: "500" }}>{errors?.status}</div>
                          )}
                        </div>
                      </Col>
                    <Col sm={3}>
                      <label htmlFor="genCat" className="fw-bold fs-6 mb-2">{t('Attach Document')}</label><br />
                      <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      disabled={isViewOnly}
                      onClick={() => setUploadFileVisibility(prev => !prev)}>
                      {t("Upload File")}
                      </Button>
                    </Col>

                    <div className="accordion mt-5" id="attachedDocument">
                      <div className="accordion-item">
                        <div className="px-4">
                          <div
                            ref={headerRef}
                            className="accordion-button bg-white cursor-pointer"
                            data-bs-toggle="collapse"
                            data-bs-target="#attachedDocumentData"
                            aria-expanded="true"
                            aria-controls="attachedDocumentData"
                            onClick={(e) => e.stopPropagation()}>
                            <div className="w-100 d-flex justify-content-between align-items-center row row-cols-1 row-cols-md-2 gy-2">
                              <div className="col-md-6 d-flex">
                                <div className={clsx("", titleCss)} style={{ color: colors.title }}>
                                  {t('Attached Document')} - {bankDetailId}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="attachedDocumentData" className="accordion-collapse collapse" aria-labelledby="headingTwo" aria-expanded="true" data-bs-parent="#attachedDocument">
                            <div className="accordion-body">
                              <Card className="p-4">
                                <List
                                  dataSource={attachmentList}
                                  height="100%"
                                  itemRender={attachmentView} />

                                {
                                  selectedAttachmentArray && selectedAttachmentArray.length > 0 &&
                                  <Button variant="outlined" className="col-2" startIcon={<DeleteIcon />} onClick={deleteSelectedAttachment}>
                                    Delete
                                  </Button>
                                }
                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    </Row>
                    {
                      (!isViewOnly || previousPage !== 'create') &&
                      <Row className="mt-5 mb-5" style={{ display: "flex", justifyContent: "center" }}>
                        <div className="col-md-2 mt-3">
                          <button
                            className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                            onClick={() => {
                              if (previousPage === 'create') {
                                resetForm();  // Reset the form
                              } else {
                                navigate('/bankApprovalList');  // Navigate to the approve bank details page
                              }
                            }} title={t(previousPage === 'create' ? 'Reset' : 'Cancel')}
                            type="button"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#0a7eac",
                            }}
                          >
                            {t(previousPage === 'create' ? 'Reset' : 'Cancel')}
                          </button>
                        </div>
                        {
                          previousPage === 'create' &&
                          <div className="col-md-2 mt-3">
                          <button
                            className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                            type="button"
                            onClick={(e) => { e.preventDefault(); setFieldValue('action', 'save'); submitForm() }} // Call handleSubmit with 'save' action
                            title={t('Save')}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#0a7eac",
                            }}
                          >
                            {t('Save')}
                          </button>
                        </div>
                        }
                        
                        {
                          previousPage === 'approve' &&
                          <>
                            <div className="col-md-2 mt-3">
                              <button
                                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                                type="button"
                                onClick={() => openComment('approve')}
                                title={t('Approve')}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "#0a7eac",
                                }}
                              >
                                {t('Approve')}
                              </button>
                            </div>
                            <div className="col-md-2 mt-3">
                              <button
                                className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                                type="button"
                                onClick={() => openComment('reject')}
                                title={t('Reject')}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "#0a7eac",
                                }}
                              >
                                {t('Reject')}
                              </button>
                            </div>
                          </>
                        }
                        {
                          previousPage === 'create' &&
                          <div className="col-md-2 mt-3">
                            <button
                              className="btn text-white rounded-pill  text-wrap  fs-6 primary-button w-100 h-45px"
                              type="button"
                              onClick={(e) => { e.preventDefault(); setFieldValue('action', 'submit'); setSubmitComment(true); }} // Call handleSubmit with 'submit' action
                              title={t('Submit')}
                              // type="submit"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#0a7eac",
                              }}
                            >
                              {t('Submit')}
                            </button>
                          </div>
                        }
                      </Row>
                    }
                    {
                    submitComment &&
                      <Popup
                      visible={submitComment}
                      hideOnOutsideClick={true}
                      onHiding={(e) => setSubmitComment(false)}
                      showCloseButton={true}
                      resizeEnabled={true}
                      title={t('Comment')}
                      width={335}
                      height={280}
                     contentRender={() => renderSubmitCommentContent(() => submitForm())} />
                 }
                  </Card>
                )
            }}
             
          </Formik>
          <UploadFile
            visibilityFlag={uploadFileVisibility}
            title={t("Upload Attachments")}
            onHideFunction={() => setUploadFileVisibility(false)}
            multipleFlag={true}
            height={400}
            fileExtensions={fileExtensions}
            onSelectedFilesChanged={onSelectedFilesChanged}
            onUploadAction={uploadFileAction} />
          <Popup
              visible={commentVisibilityFlag}
              hideOnOutsideClick={true}
              onHiding={(e) => setCommentVisibilityFlag(false)}
              showCloseButton={true}
              resizeEnabled={true}
              title={t('Comment')}
              width={335}
              height={280}
              contentRender={renderContent} />
        </div>
        </div>
        </div>
    </>
  )
}
